import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { connect } from "react-redux";
import LoaderSpinner from "./LoaderSpinner";
import { Row, Col, Form, Button, Dropdown, DropdownButton, Table, ButtonGroup, Modal } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import { usersApi } from "../libs/postApi_Lib";

const mapStateToProps = (state, props) => ({
    usersData: state.usersData,
    userSelected: props.userSelected,
    setUserSelected: props.setUserSelected
})

const mapDispatchToProps = dispatch => ({
    setClientData(updateClientData){
        dispatch({
            type: "SET_CLIENT_DATA",
            updateClientData
        })
    },
    setIncomingMsg(incomingMsgCenter){
        dispatch({
            type: "SHOW_MSG_CENTER",
            incomingMsgCenter
        })
    }
})

const onLoad = (setClientData, setIncomingMsg, setOkToRender, cliente, setUserClients, setClientsToSelect) => {
    console.log("actualizando clientes");
    API.get("vcm", "/getClients")
        .then((value)=>{
            setClientData(value);
            serializeClients(value, cliente, setUserClients, setClientsToSelect);
            setOkToRender(true);
        })
        .catch(error => {
            console.log(error);
            const time = new Date();
            const hour = time.getHours();
            const minute = time.getMinutes();
            const incomingMsgCenter={
            title: "Clientes",
            time: hour + ":" + minute,
            text: "Error: " + error,
            show: true
        };
        setIncomingMsg(incomingMsgCenter);
        });
}

const serializeClients = (value, cliente, setUserClients, setClientsToSelect) => {
    var usrClient = [];
    var client2Select = value;
    for (let index = 0; index < cliente.length; index++) {
        const element = cliente[index];
        for (let i = 0; i < client2Select.length; i++) {
            const customerData = client2Select[i];
            if (customerData.clientId===element){
                usrClient.push(customerData);
                client2Select.splice(i, 1);
            }
        }
    }
    setUserClients(usrClient);
    setClientsToSelect(client2Select);
}

const UsersDataForm = ({usersData, userSelected, setUserSelected, setClientData, setIncomingMsg}) => {
    //Variables
    const [okToRender, setOkToRender] = useState(false);
    var user = [];
    for (let i = 0; i < usersData.length; i++) {
        const userList = usersData[i];
        if (userList.userId === userSelected){
            user.push(userList);
        }
    }
    const [nombre, setNombre] = useState(user[0].nombre);
    const [apellido, setApellido] = useState(user[0].apellido);
    const [rol, setRol] = useState(user[0].rol);
    const [cliente, setCliente] = useState(user[0].cliente);
    const [showModalClientToSelect, setShowModalClientToSelect] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [btnText, setBtnText] = useState("Guardar");
    const email = user[0].email;
    const userId = user[0].userId;

    const [userClients, setUserClients] = useState([{
        clientId: "",
        nombre: "",
        id_tributaria: ""
    }]);
    const [clientsToSelect, setClientsToSelect] = useState([{
        clientId: "",
        nombre: "",
        id_tributaria: ""
    }]);

    useEffect(()=>{
        onLoad(setClientData, setIncomingMsg, setOkToRender, cliente, setUserClients, setClientsToSelect);
    }, [setClientData, setIncomingMsg, cliente]);

    
    //Funciones
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        setBtnText("");
        var clientsIds = [];
        for (let i = 0; i < userClients.length; i++) {
            const element = userClients[i];
            clientsIds.push(element.clientId);
        }
        setCliente(clientsIds);
        let user = {
            userId: userId,
            email:email,
            rol:rol,
            nombre:nombre,
            apellido:apellido,
            cliente:clientsIds
        }
        usersApi(user)
        .then((response) => {
            const time = new Date();
            const hour = time.getHours();
            const minute = time.getMinutes();
            const incomingMsgCenter={
                title: "Actualizar usuario",
                time: hour + ":" + minute,
                text: response.msg,
                show: true
            }
            setIncomingMsg(incomingMsgCenter);
            setUserSelected(false);
        });
    }

    const validateSubmit = () => {
        return nombre.length > 0 &&
        apellido.length > 0 &&
        rol.length > 0 &&
        userClients.length > 0;
    }

    const closeModalClientToSelect = () => {
        setShowModalClientToSelect(false);
    }

    const handleModalClientToSelect = () => {
        setShowModalClientToSelect(true);
    }

    const quitClient = (index) => {
        var tempUserClient = [...userClients];
        var tempClientToSelect = [...clientsToSelect];
        tempClientToSelect.push(tempUserClient[index]);
        tempUserClient.splice(index, 1);
        setUserClients(tempUserClient);
        setClientsToSelect(tempClientToSelect);
    }

    const handleAddClient = (index) => {
        var tempClientToSelect = [...clientsToSelect];
        var tempUserClient = [...userClients];
        tempUserClient.push(tempClientToSelect[index]);
        tempClientToSelect.splice(index, 1);
        setUserClients(tempUserClient);
        setClientsToSelect(tempClientToSelect);
    }

    //Render
    if (okToRender){
        return (
            <Col md={8}>
                <form onSubmit={handleSubmit}>
                    <Form.Group controlId="nombre" as={Row}>
                        <label className="form-label">Nombres del usuario</label>
                        <Form.Control 
                            autoFocus
                            type="text"
                            value={nombre}
                            onChange={e => setNombre(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="apellido" as={Row}>
                        <label className="form-label">Apellidos del usuario</label>
                        <Form.Control 
                            type="text"
                            value={apellido}
                            onChange={e=> setApellido(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="rol" as={Row}>
                        <label className="form-label">Rol</label>
                        <Form.Control 
                            as="select"
                            type="text"
                            value={rol}
                            onChange={e=> setRol(e.target.value)}
                        >
                            <option>guest</option>
                            <option>admin</option>
                            <option>root</option>
                        </Form.Control>
                    </Form.Group>
                    <hr></hr>
                    <Row>
                        <Col className="text-left"><h3>Clientes</h3>
                        </Col>
                        <Col className="text-right">
                            <Button onClick={()=>handleModalClientToSelect()} title="Adicionar Clientes"><i className="fas fa-plus"></i></Button>
                            <Modal show={showModalClientToSelect} onHide={closeModalClientToSelect} size="lg">
                                <Modal.Header closeButton>
                                <Modal.Title>Clientes:</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>id_tributaria</th>
                                                <th>nombre</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {clientsToSelect.map((v, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{v.id_tributaria}</td>
                                                        <td>{v.nombre}</td>
                                                        <td>
                                                            <Button variant="success" onClick={()=>handleAddClient(i)} title="Adicionar"><i className="fas fa-check"></i></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Modal.Body>
                                <Modal.Footer>
                                <h4>Vroom Console Manager</h4>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>
                    <Table striped bordered hover size ="sm">
                        <thead>
                            <tr>
                                <th>id_tributaria</th>
                                <th>nombre</th>
                            </tr>
                        </thead>    
                        <tbody>
                            {userClients.map((v,i) => {
                                return (
                                <tr key={i}>
                                    <td>{v.id_tributaria}</td>
                                    <td>{v.nombre}</td>
                                    <td>
                                        <DropdownButton 
                                            title = {<i className="far fa-trash-alt"></i>}
                                            variant="danger"
                                            as={ButtonGroup}
                                        >
                                            <Dropdown.Header>¿Seguro?</Dropdown.Header>
                                            <Dropdown.Item onClick={()=> {quitClient(i)}}>Si, quita este cliente</Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <hr></hr>
                    <LoaderButton 
                        block
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validateSubmit()}
                    >{btnText}</LoaderButton>
                </form>
            </Col>
        );
    } else {
        return <LoaderSpinner />
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(UsersDataForm)