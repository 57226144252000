import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import LoaderSpinner from "./LoaderSpinner";
import { connect } from "react-redux";
import { Button, Table, Row, Col, DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import ClientDataForm from "./clientsDataForm";

const mapStateToProps = state => ({
    clientData: state.clientData
});

const mapDispatchToProps = dispatch => ({
    setClientData(updateClientData){
        dispatch({
            type: "SET_CLIENT_DATA",
            updateClientData
        })
    },
    setIncomingMsg(incomingMsgCenter){
        dispatch({
            type: "SHOW_MSG_CENTER",
            incomingMsgCenter
        })
    },
});

function retrieveCustomers(setClientData, setIncomingMsg){
    console.log("Actualizando clientes");
    API.get("vcm", "/getClients")
    .then(function (response){
        setClientData(response);
    }).catch(function(err) {
        console.log(err);
        //Enviar notificacion a MsgCenter => no se pudo encontrar la información del usuario
        //No se ha probado el mensaje de error como viene ni como se ve
        const time = new Date();
        const hour = time.getHours();
        const minute = time.getMinutes();
        const incomingMsgCenter={
            title: "Clientes",
            time: hour + ":" + minute,
            text: "Error: " + err.msg,
            show: true
        }
        setIncomingMsg(incomingMsgCenter);
    });
}

const deleteClient = (id, setClientData) => {
    //El api de eliminacion debe consultar si hay room asignadas a este cliente
    //Si las hay se responde indicando que no se puede eliminar el cliente, mientas haya room asignadas a él,
    //de esta manera se obliga al admin ha que vaya a eliminar primero las rooms, la idea es que al eliminar
    //las rooms tambien se le notifique sobre las grabaciones de las rooms, y que decida si las elimina o no.
    console.log("Eliminando...");
    console.log(id);
    API.get("vcm", "/deleteClient")
    .then(function (response){
        setClientData(response);
        //MsgCenter Cliente eliminando
    }).catch(function(err) {
        console.log(err);
        //Enviar notificacion a MsgCenter => con la respuesta devuelta
    });
}

const renderClientList = (clientData, setClientSelected, setClientData) => {
    var arrClients = [];
    for (let i = 0; i < clientData.length; i++) {
        const client = clientData[i];
        const param = {
            "clientId": client.clientId,
            "id_tributaria": client.id_tributaria,
            "nombre": client.nombre
        }
        arrClients.push(param);
    }
    return (
        <div className="client-container">
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>id_tributaria</th>
                        <th>nombre</th>
                    </tr>
                </thead>
                <tbody>
                    {arrClients.map((v,i) => {
                        return (
                            <tr key={i}>
                                <td name={"id_t"+i}>{v.id_tributaria}</td>
                                <td name={"nombre"+i}>{v.nombre}</td>
                                <td>
                                    <Button variant="success" title="Editar" onClick={() => setClientSelected(v.clientId)}><i className="far fa-edit"></i></Button>
                                    <DropdownButton 
                                        title={<i className="far fa-trash-alt"></i>}
                                        variant="danger"
                                        as={ButtonGroup}
                                        >
                                            <Dropdown.Header>¿Seguro?</Dropdown.Header>
                                            <Dropdown.Item onClick={()=> deleteClient(v.clientId, setClientData)} as="button">Si, elimina este cliente</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

const CustomersEdit = ({clientData, setClientData, setIncomingMsg}) => {
    useEffect(()=>{
        retrieveCustomers(setClientData, setIncomingMsg);
    }, [setClientData, setIncomingMsg]);
    const [clientSelected, setClientSelected] = useState(false);
    if (clientData.length > 0){
        if (clientSelected===false){
            return (
                <div>
                    <Row className="action-buttons-container mt-3">
                        <Col className="text-right">
                            <Button onClick={()=>retrieveCustomers(setClientData, setIncomingMsg)} title="Recargar"><i className="fas fa-sync-alt"></i></Button>
                        </Col>
                    </Row>
                    <hr></hr>
                    {renderClientList(clientData, setClientSelected, setClientData)}
                </div>
            );
        } else {
            return (
                <div>
                    <Row className="action-buttons-container mt-3">
                        <Col className="text-right">
                            <Button onClick={()=>setClientSelected(false)} title="Volver"><i className="fas fa-chevron-left"></i></Button>
                        </Col>
                    </Row>
                    <hr></hr>
                    <ClientDataForm clientSelected={clientSelected} isUpdate={true} setClientSelected={setClientSelected}/>
                </div>
            );
        }
    } else {
        return (<div>
            <LoaderSpinner />
        </div>);
    }
    
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersEdit);