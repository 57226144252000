import React, { useState, useEffect, useCallback } from "react";
import { Col, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import RoomsCreate from "../components/roomsCreate";
import RoomsEdit from "../components/roomsEdit";
import { API } from "aws-amplify";
import { connect } from "react-redux";
import LoaderSpinner from "./LoaderSpinner";

function loadPage(page){
    switch (page) {
        case 1:
            return (<RoomsCreate />)
        
        case 2:
            return (<RoomsEdit />)

        default:
            return (<div className="welcomeTitle"><h2><i className="fas fa-terminal"> </i> Administrar salas </h2></div>);      
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    setClientData(updateClientData){
        dispatch({
            type: "SET_CLIENT_DATA",
            updateClientData
        })
    },
    setIncomingMsg(incomingMsgCenter){
        dispatch({
            type: "SHOW_MSG_CENTER",
            incomingMsgCenter
        })
    }
});

const Rooms = ({setClientData, setIncomingMsg}) => {

    const [page, setPage] = useState(0);
    const [okToRender, setOkToRender] = useState(false);

    //Funciones
    const onLoad = useCallback(() => {
        console.log("Actualizando clientes");
        API.get("vcm", "/getClients")
        .then((value) => {
            setClientData(value);
            setOkToRender(true);
        }).catch((error) => {
            console.log(error);
            const time = new Date();
            const hour = time.getHours();
            const minute = time.getMinutes();
            const incomingMsgCenter = {
                title: "Rooms",
                time: hour + ":" + minute,
                text: "Error: " + error,
                show: true
            };
            setIncomingMsg(incomingMsgCenter);
        })
    },[setClientData,setIncomingMsg])

    useEffect(()=>{
        onLoad();
    }, [onLoad]);

    if(okToRender){
        return (
            <div>
                <div>
                    <Nav variant="tabs" >
                        <Nav.Item>
                            <LinkContainer to="#"><Nav.Link eventKey="/P1" onClick={()=>setPage(1)}>Crear</Nav.Link></LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="/#"><Nav.Link eventKey="/P2" onClick={()=>setPage(2)}>Administrar</Nav.Link></LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="#"><Nav.Link eventKey="/P3" onClick={()=>setPage(0)}>Eliminar</Nav.Link></LinkContainer>
                        </Nav.Item>
                    </Nav>
                </div>
                <Col>
                    {loadPage(page)}
                </Col>
            </div>
        )
    } else {
        return <LoaderSpinner />
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Rooms);