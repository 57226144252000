import { createStore } from "redux";

const initialState = {
    isAuthenticated: [{authenticated: false}],
    userData: [{
        userId: "",
        nombre: "",
        apellido: "",
        email: "",
        rol: "",
        cliente: "",
        zona: ""
    }],
    roomsData: [],
    clientData: [],
    usersData:[],
    msgCenterData: {
        title: "",
        time: "",
        text: "",
        show: false
    }
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case "USER_PERFORM_LOGIN":
            return {
                ...state,
                isAuthenticated: action.auth_request.isAuthenticated
            }
        case "SAVE_USER_DATA":
            return {
                ...state,
                userData: action.newUserData.userData
            }
        case "SHOW_MSG_CENTER":
            return {
                ...state,
                msgCenterData: action.incomingMsgCenter
            }
        case "SET_CLIENT_DATA":
            return {
                ...state,
                clientData: action.updateClientData
            }
        case "SET_USERS_DATA":
            return {
                ...state,
                usersData: action.updateUsersData
            }
        case "SET_ROOMS_DATA":
            return {
                ...state,
                roomsData: action.updateRoomsData
            }
        default:
            break;
    }
    return state;
}

export default createStore(reducer)