import React, {useState} from "react";
import { Nav, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import CustomersCreate from "./customersCreate";
import CustomersEdit from "./customersEdit";
import { connect } from "react-redux";

const mapStateToProps = state => ({
    userRol: state.userData[0].rol
})

const mapDispatchToProps = distpach => ({

})

function loadPage(page){
    switch (page){
        case 1:
            return <CustomersCreate />
        case 2:
            return <CustomersEdit />
        default:
            return (<div className="welcomeTitle"><h2><i className="fas fa-terminal"> </i> Administrar clientes </h2></div>);      
    }
}

const Customers = ({userRol}) => {

    const [page, setPage] = useState(0);

    return (
        <div>
            <div>
                <Nav variant="tabs">
                    {userRol==="root" ? 
                    <Nav.Item>
                        <LinkContainer to="#"><Nav.Link eventKey="/P1" onClick={()=>setPage(1)}>Nuevo</Nav.Link></LinkContainer>
                    </Nav.Item> : ""}
                    <Nav.Item>
                        <LinkContainer to="#"><Nav.Link eventKey="/P2" onClick={()=>setPage(2)}>Editar</Nav.Link></LinkContainer>
                    </Nav.Item>
                </Nav>
            </div>
            <Col>
                {loadPage(page)}
            </Col>
        </div>
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(Customers);