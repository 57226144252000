import React from "react";
import Logo from "../img/Logo.png";
import { Navbar, NavDropdown, Toast, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import msgCenterIcon from "../img/msgIcon.svg";
import "./header.css"

const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated[0].authenticated,
    userName: state.userData[0].nombre + " " + state.userData[0].apellido,
    msgCenter: state.msgCenterData
})

const mapDispatchToProps = dispatch => ({
    setLoginState(auth_request){
        dispatch({
            type: "USER_PERFORM_LOGIN",
            auth_request
        })
    },
    setIncomingMsg(incomingMsgCenter){
        dispatch({
            type: "SHOW_MSG_CENTER",
            incomingMsgCenter
        })
    },
    setUserData(newUserData){
        dispatch({
            type: "SAVE_USER_DATA",
            newUserData
        })
    }
})

async function handleLogout(setLoginState, history, setIncomingMsg, setUserData){
    try {
        console.log("Cerrando sesion");
        await Auth.signOut();
        const auth_request = {
            isAuthenticated: [{
                authenticated: false
            }]
        }
        setLoginState(auth_request);
        const time = new Date();
        const hour = time.getHours();
        const minute = time.getMinutes();
        const incomingMsgCenter={
            title: "Hasta pronto",
            time: hour + ":" + minute,
            text: "Cierre de sesión exitoso",
            show: true
        }
        setIncomingMsg(incomingMsgCenter);
        const newUserData = {
            userData: [{
                userId: "",
                nombre: "",
                apellido: "",
                email: "",
                rol: "",
                cliente: "",
                zona: ""
            }],
        }        
        setUserData(newUserData);
        history.push("/login");
    } catch (error){
        console.log(error);
    }
}

const goToProfile = (history) => {
    const profile = {
        profile:true
    };
    history.push({
        pathname: '/console', 
        props: profile
    });
}

function closeToast(setIncomingMsg){
    const incomingMsgCenter={
        title: "",
        time: "",
        text: "",
        show: false
    }
    setIncomingMsg(incomingMsgCenter); 
}

const Header = ({isAuthenticated, msgCenter, userName, setLoginState, setIncomingMsg, setUserData}) => {
    const history = useHistory ();
    return (
        <div className="top-container" aria-live="polite" aria-atomic="true"> 
            <Navbar bg="light" expand="lg">
                <Col className="text-left">
                    <Row>
                        <Navbar.Brand className="home-header-logo" href="http://www.gvalor.co"><img src={Logo} alt="headerLogo" className="header-logo"/></Navbar.Brand>
                        <Link to="/" className="mt-auto mb-auto">{isAuthenticated ? <h4><i className="fas fa-chevron-right"></i><strong> VirtualRoom Console Manager</strong></h4> 
                        : <h4><i className="fas fa-chevron-right"></i><strong> Bienvenido</strong></h4>}</Link>
                    </Row>                
                </Col>
                <Row>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Col md="auto">
                            {isAuthenticated ? 
                            <Row>                            
                                <Navbar.Text>Hola!  </Navbar.Text>
                                <NavDropdown title={userName} id="basic-nav-dropdown">
                                <LinkContainer to="#"><NavDropdown.Item href="#" onClick={() => goToProfile(history)}>Perfil</NavDropdown.Item></LinkContainer>
                                    <NavDropdown.Divider />
                                    <LinkContainer to="#"><NavDropdown.Item href="#" onClick={() => handleLogout(setLoginState, history, setIncomingMsg, setUserData)}>Cerrar Sesion</NavDropdown.Item></LinkContainer>
                                </NavDropdown>                    
                            </Row>
                            : ""
                            }
                        </Col>
                    </Navbar.Collapse>
                </Row>
            </Navbar>   
            <Toast
                style={{
                position: 'absolute',
                top: '2px',
                right: 0,
                }}
                onClose={() => closeToast(setIncomingMsg)} 
                show={msgCenter.show} 
                delay={8000} 
                autohide
            >
                <Toast.Header>
                    <img src={msgCenterIcon} className="rounded mr-2 msg-center-icon" alt="Icon MsgCenter" />
                    <strong className="mr-auto">{msgCenter.title}</strong>
                    <small>{msgCenter.time}</small>
                </Toast.Header>
                <Toast.Body>{msgCenter.text}</Toast.Body>
            </Toast>            
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);