import React, { useState } from "react";
import UserEdit from "./userEdit";
import { Col,Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function loadPage(page){
    switch (page){
        case 1:
            break; 
        case 2:
            return <UserEdit />
        default:
            return (<div className="welcomeTitle"><h2><i className="fas fa-terminal"> </i> Administrar usuarios </h2></div>);        
    }
}

const Users = () => {
    const [page, setPage] = useState(0);

    return (
        <div>
            <div>
                <Nav variant="tabs">
                    <Nav.Item>
                        <LinkContainer to="#"><Nav.Link eventKey="/P2" onClick={()=>setPage(2)}>Editar</Nav.Link></LinkContainer>
                    </Nav.Item>
                </Nav>
            </div>
            <Col>
                {loadPage(page)}
            </Col>
        </div>
    );
    
}

export default Users;