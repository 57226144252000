import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import { PostApi } from "../libs/postApi_Lib";

const ClientDataForm = ({clientData, clientId, isUpdate, setIncomingMsg, setClientSelected}) => {
    //Variables
    var client = [];
    if (isUpdate){
        for (let i = 0; i < clientData.length; i++) {
            const clientExt = clientData[i];
            const customer = clientExt.clientId;
            if (customer === clientId){
                client.push(clientExt);
            }
        }        
    } else {
        const clientCero = {
            nombre: "",
            id_tributaria: "",
            direccion: "",
            ciudad: "",
            pais: "",
            servidores: [{
                id: "1",
                servidor: "",
                BBB_Secret: "",
            }]
        };
        client.push(clientCero);
    }
    const [nombre, setNombre] = useState(client[0].nombre);
    const [id_tributaria,setIdTributaria] = useState(client[0].id_tributaria);
    const [direccion, setDireccion] = useState(client[0].direccion);
    const [ciudad, setCiudad] = useState(client[0].ciudad);
    const [pais, setPais] = useState(client[0].pais);
    var servers= client[0].servidores;
    const [qServers, setqServers] = useState(servers.length);
    const [serversFormField, setServersFormField] = useState([{
        id: servers[0].id,
        servidor: servers[0].servidor,
        BBB_Secret: servers[0].BBB_Secret,
    }]);
    const [showServers, setShowServers] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [btnText, setBtnText] = useState("Guardar");

    //Funciones
    const handleSubmit = (event) => {
        event.preventDefault()
        setIsLoading(true);
        setBtnText("");
        var clientData = "";
        if (clientId.length > 0){
            clientData={
                "clientId": clientId,
                "nombre": nombre,
                "direccion": direccion,
                "id_tributaria": id_tributaria,
                "ciudad": ciudad,
                "pais": pais,
                "servidores": serversFormField
            }
            PostApi(clientData, true)
            .then((response)=>{
                const time = new Date();
                const hour = time.getHours();
                const minute = time.getMinutes();
                const incomingMsgCenter={
                    title: "Actualizar cliente",
                    time: hour + ":" + minute,
                    text: response.msg,
                    show: true
                }
                setIncomingMsg(incomingMsgCenter);
            })
        } else {
            clientData={
                "nombre": nombre,
                "id_tributaria": id_tributaria,
                "direccion": direccion,
                "ciudad": ciudad,
                "pais": pais,
                "servidores": serversFormField
            }
            PostApi(clientData, false)
            .then((response)=>{
                const time = new Date();
                const hour = time.getHours();
                const minute = time.getMinutes();
                const incomingMsgCenter={
                    title: "Actualizar cliente",
                    time: hour + ":" + minute,
                    text: response.msg,
                    show: true
                }
                setIncomingMsg(incomingMsgCenter);
            });
        }
        if (isUpdate){
            setClientSelected(false);
        } else {
            client = [];
            const clientCero = {
                nombre: "",
                id_tributaria: "",
                direccion: "",
                ciudad: "",
                pais: "",
                servidores: [{
                    id: "1",
                    servidor: "",
                    BBB_Secret: "",
                }]
            };
            client.push(clientCero);
            setNombre(client[0].nombre);
            setIdTributaria(client[0].id_tributaria);
            setDireccion(client[0].direccion);
            setCiudad(client[0].ciudad);
            setPais(client[0].pais);
            servers= client[0].servidores;
            setqServers(servers.length);
            const serverFF = {
                id: servers[0].id,
                servidor: servers[0].servidor,
                BBB_Secret: servers[0].BBB_Secret,
            };
            setServersFormField([serverFF]);
            setBtnText("Guardar");
            setIsLoading(false);
            //console.log(client);
            //console.log(serverFF);
            //console.log(serversFormField);
        }
    }

    const validateSubmit = () => {
        return nombre.length > 0 &&
        id_tributaria.length > 0 &&
        direccion.length > 0 &&
        ciudad.length > 0 &&
        pais.length > 0 &&
        serversFormField[0].servidor.length > 1;
    };

    const updateqServers = () => {
        for (let i = 0; i < servers.length; i++) {
            const servidorData = servers[i];
            const list = {
                id: servidorData.id,
                servidor: servidorData.servidor,
                BBB_Secret: servidorData.BBB_Secret
            }
            if (i===0){
                setServersFormField([list]);
            } else {
                setServersFormField([...serversFormField, list]);
            }      
        }
        setqServers(servers.length);
        setShowServers(true);
    }

    const handleInputChange = (e, index) => {
        const { id, value } = e.target;
        const list = [...serversFormField];
        list[index][id] = value;
        setServersFormField(list);
    };
    
    const handleAddClick = (qS) => {
        setServersFormField([...serversFormField, { id: qS, servidor: "", BBB_Secret: "" }]);
        setqServers(qS);
    };
    
    const handleRemoveClick = index => {
        //if (index !== servers.lenght)
        const list = [...serversFormField];
        list.splice(index, 1);
        setServersFormField(list);
        setqServers(qServers-1);
    };

    return (
        <Col md={8} className="mt-3">
            <form onSubmit={handleSubmit}>
                <Form.Group controlId="nombre" as={Row}>
                    <label className="form-label">Nombre del cliente</label>
                    <Form.Control
                        autoFocus
                        type="text"
                        value={nombre}
                        readOnly={isUpdate}
                        onChange={e => setNombre(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="id_tributaria" as={Row}>
                    <label className="form-label">Identificación tributaria</label>
                    <Form.Control 
                        type="text"
                        value={id_tributaria}
                        onChange={e => setIdTributaria(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="direccion" as={Row}>
                    <label className="form-label">Dirección principal</label>
                    <Form.Control 
                    type="text"
                        value={direccion}
                        onChange={e => setDireccion(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="ciudad" as={Row}>
                    <label className="form-label">Ciudad</label>
                    <Form.Control 
                        type="text"
                        value={ciudad}
                        onChange={e => setCiudad(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="pais" as={Row}>
                    <label className="form-label">País</label>
                    <Form.Control 
                        type="text"
                        value={pais}
                        onChange={e => setPais(e.target.value)}
                    />
                </Form.Group>
                <hr></hr>
                <div className="text-right">
                    <Button onClick={() => updateqServers()} title="Mostrar Servidores"><i className="fas fa-server"></i></Button>
                </div>
                <hr></hr>
                {showServers && <Row>
                    <label className="form-label">Cantidad de servidores</label>
                    <Col xs={2}>                            
                        <Form.Control 
                            readOnly
                            type="text"
                            value={qServers}
                        />
                    </Col>
                    <Col >
                        <div className="text-right">
                            <Button onClick={()=>handleAddClick(qServers + 1)} title="Adicionar Servidor"><i className="fas fa-plus"></i></Button>
                            <Button onClick={()=>handleRemoveClick(serversFormField.length - 1)} title="Quitar Servidor"><i className="fas fa-minus"></i></Button>
                        </div>
                    </Col>
                </Row>}
                <div>
                    {showServers && serversFormField.map((x, i) => {
                        return (
                            <div key={x.id + "divServer"}>
                                <Form.Group controlId="servidor" as={Row}>
                                    <label className="form-label">URL Servidor ({x.id})</label>
                                    <Form.Control 
                                        name={x.id} 
                                        type="text"
                                        value={x.servidor}
                                        onChange={e => handleInputChange(e, i)}
                                        />
                                    <Form.Text className="text-muted">https://example.com.co/bigbluebutton/</Form.Text>
                                </Form.Group>
                                <Form.Group controlId="BBB_Secret" as={Row}>
                                    <label className="form-label">Secret Servidor ({x.id})</label>
                                    <Form.Control 
                                        name={x.id}
                                        type="text"
                                        value={x.BBB_Secret}
                                        onChange={e=> handleInputChange(e, i)}
                                    />
                                </Form.Group>
                            </div>
                        );
                    })}
                </div>
                <LoaderButton 
                    block
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateSubmit()}
                >{btnText}</LoaderButton>
            </form>
        </Col>
    )
}

const mapStateToProps = (state, props) =>({
    clientData: state.clientData,
    clientId: props.clientSelected,
    isUpdate: props.isUpdate,
    setClientSelected: props.setClientSelected
});

const mapDispatchToProps = dispatch =>({
    setIncomingMsg(incomingMsgCenter){
        dispatch({
            type: "SHOW_MSG_CENTER",
            incomingMsgCenter
        })
    },
});

export default connect(mapStateToProps, mapDispatchToProps) (ClientDataForm);