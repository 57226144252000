import React, { useState, useEffect } from "react";
import { Row, Col, Nav } from "react-bootstrap";
import "./console.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Rooms from "../components/rooms";
import Customers from "../components/customers";
import Users from "../components/users";
import UserProfile from "../components/userProfile";
import { getUserDataApi } from "../libs/postApi_Lib";
import Analitics from "./analitics";

const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    rol: state.userData[0].rol
})

const mapDispatchToProps = dispatch => ({
    setLoginState(auth_request){
        dispatch({
            type: "USER_PERFORM_LOGIN",
            auth_request
        })
    },
    setUserData(newUserData){
        dispatch({
            type: "SAVE_USER_DATA",
            newUserData
        })
    },
    setIncomingMsg(incomingMsgCenter){
        dispatch({
            type: "SHOW_MSG_CENTER",
            incomingMsgCenter
        })
    },
})

async function onload(setLoginState, history, setUserData, setIncomingMsg){
    console.log("Verificando estado de usuario");
    try {
        const userSessionState = await Auth.currentSession();
        const auth_request = {
            isAuthenticated: [{
                authenticated: true
            }]
        }
        console.log("Usuario Autenticado");
        setLoginState(auth_request);
        const userEmail = userSessionState.idToken.payload.email;
        loadUserData(userEmail, setUserData, setIncomingMsg)
    } catch (error){
        console.log("Usuario No Autenticado");
        history.push("/login");
    }
}

async function loadUserData(userEmail, setUserData, setIncomingMsg){
    getUserDataApi(userEmail)
    .then((response)=>{
        setUserData(response);
        const time = new Date();
        const hour = time.getHours();
        const minute = time.getMinutes();
        const incomingMsgCenter={
            title: "Bienvenido",
            time: hour + ":" + minute,
            text: response.userData[0].nombre + " " + response.userData[0].apellido,
            show: true
        }
        setIncomingMsg(incomingMsgCenter);
    })
}

function menuSelected(menuOption){
    switch (menuOption) {
        case "Analitics":
            return (
                <Analitics />
            )
        case "Salas":
            return (
                <Rooms />
            )
        case "Usuarios":
            return (
                <Users />
            )
        
        case "Clientes": 
            return <Customers />
        
        case "profile":
            return <UserProfile />
        default:
            return (<div className="welcomeTitle"><h2><i className="fas fa-terminal"> </i> Bienvenido </h2></div>);
    }
}

function renderConsole (menuOption, setMenuOption, rol, showProfile){
    const adminAccess = ["Analitics", "Salas", "Clientes"];
    const rootAccess = ["Analitics", "Salas", "Usuarios", "Clientes"];
    if (showProfile === true){
        return (
            <Col>
                {menuSelected("profile")}
            </Col>);
    } else {
        switch (rol) {
            case "admin":
                return (
                    <Row>
                        <Col xs={2} className ="side-menu-container">
                            <h5>Menú</h5>
                            <hr></hr>
                            <Nav defaultActiveKey="/home" className="flex-column">
                            {adminAccess.map((x,i) =>{
                                if (i%2 === 0){
                                    return (
                                        <LinkContainer to="#" className="mainMenuOption" key={i}><Nav.Link href="#" onClick={()=> setMenuOption(x)}>{x}</Nav.Link></LinkContainer>        
                                    )
                                } else {
                                    return (
                                        <LinkContainer to="#" className="mainMenuOptionAlt" key={i}><Nav.Link href="#" onClick={()=> setMenuOption(x)}>{x}</Nav.Link></LinkContainer>        
                                    )
                                }
                            })}
                            </Nav>
                        </Col>
                        <Col >
                            {menuSelected(menuOption)}
                        </Col>
                    </Row>
                );
            case "root":
                return (
                    <Row>
                        <Col xs={2} className ="side-menu-container">
                            <h5>Menú</h5>
                            <hr></hr>
                            <Nav defaultActiveKey="/home" className="flex-column">
                            {rootAccess.map((x,i) =>{
                                if (i%2 === 0){
                                    return (
                                        <LinkContainer to="#" className="mainMenuOption" key={i}><Nav.Link href="#" onClick={()=> setMenuOption(x)}>{x}</Nav.Link></LinkContainer>        
                                    )
                                } else {
                                    return (
                                        <LinkContainer to="#" className="mainMenuOptionAlt" key={i}><Nav.Link href="#" onClick={()=> setMenuOption(x)}>{x}</Nav.Link></LinkContainer>        
                                    )
                                }
                            })}
                            </Nav>
                        </Col>
                        <Col >
                            {menuSelected(menuOption)}
                        </Col>
                    </Row>
                );
            default:
                break;
        }
    }
    return (
        <Row className="justify-content-md-center guest-container">
            <h2>Bienvenido a la consola para la administración de salas virtuales. En un momento seras redirigido</h2>
        </Row>
    );
}

const Console = ({isAuthenticated, rol, setLoginState, setUserData, setIncomingMsg}) => {
    const location = useLocation();
    var showProfile = false;
    if (location.props){
        showProfile=location.props.profile;
    }
    const history = useHistory();
    useEffect(() => {        
        onload(setLoginState, history, setUserData, setIncomingMsg);
    },[setLoginState, history, setUserData, setIncomingMsg]);

    const [menuOption, setMenuOption] = useState(0);
    
    if (isAuthenticated[0].authenticated){
        switch (rol) {
            case "guest":
                return (<div className="guest-container"><h2>Tu cuenta aún no esta habilitada para acceder a la consola</h2></div>);
        
            default:
                const console = renderConsole(menuOption, setMenuOption, rol, showProfile);
                return console;
        }
    }
    return (<div></div>);
}

export default connect(mapStateToProps, mapDispatchToProps)(Console)