import React from "react"
import { Route, Switch } from "react-router-dom";
import Login from "./containers/login.js";
import Registrarse from "./containers/registrarse.js";
import Console from "./containers/console.js";
import Join from './containers/join';
import Recordings from './containers/recordings';

export default function Routes (){
    return (
        <Switch>
            <Route exact path="/">
                <Console />
            </Route>
            <Route exact path="/recordings">
                <Recordings />
            </Route>
            <Route exact path="/join">
                <Join />
            </Route>
            <Route exact path="/login">
                <Login />
            </Route>
            <Route exact path="/registrarse">
                <Registrarse />
            </Route>
            <Route exact path="/console">
                <Console />
            </Route>
        </Switch>
    );
}