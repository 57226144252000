import React, { useEffect, useState, useRef, useCallback } from "react";
import { API } from "aws-amplify";
import LoaderSpiner from "../components/LoaderSpinner";
import { Table, Button, DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { deleteRecordingApi } from "../libs/postApi_Lib";
import { connect } from "react-redux";

const queryString = (variable, url = window.location.href) => {
    variable = variable.replace(/[[]]/g, "\\$&");
    const regex = new RegExp("[?&]" + variable + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);
    if (!results) {
        return null;
      }
      if (!results[2]) {
        return "";
      }
    
      return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch => ({
  setIncomingMsg(incomingMsgCenter){
    dispatch({
        type: "SHOW_MSG_CENTER",
        incomingMsgCenter
    })
  }
});

const deleteRecording = (recordId, server, cId, setIncomingMsg) => {
  const recToDelete = {
    recordingId: recordId,
    clientId: cId,
    roomServer: server
  }
  console.log("Eliminando grabación...");
  deleteRecordingApi(recToDelete)
  .then((response)=>{
    const time = new Date();
    const hour = time.getHours();
    const minute = time.getMinutes();
    const incomingMsgCenter = {
      title: "Grabaciones",
      time: hour + ":" + minute,
      text: response.msg,
      show: true
    };
    setIncomingMsg(incomingMsgCenter);
  }).catch(error => {
    console.log(error);
  });
}

const Recordings = ({setIncomingMsg}) => {
    const mId = queryString('mId');
    const cId = queryString('cId');
    const server = queryString('server');
    const [renderRecords, setRenderRecords] = useState(true);
    const recordingsTable = useRef([]);
    const columns = useRef([
      {dataField: "name", text: "Room", sort: true},
      {dataField: "startTime", text: "Inicio", sort: true},
      {dataField: "endTime", text: "Fin"},
      {dataField: "playback", text: "Playback"},
    ]);
    /*var columns = [
      {dataField: "name", text: "Room"},
      {dataField: "startTime", text: "Inicio"},
      {dataField: "endTime", text: "Fin"},
      {dataField: "playback", text: "Playback"},
    ];*/

    /*const dummyData = [
      {recordID: "1", name: "test1", startTime: "init", endTime: "fin", playback: "myplayback"},
      {recordID: "2", name: "test2", startTime: "init", endTime: "fin", playback: "myplayback"},
      {recordID: "3", name: "test3", startTime: "init", endTime: "fin", playback: "myplayback"},
      {recordID: "4", name: "test4", startTime: "init", endTime: "fin", playback: "myplayback"},
    ]*/

    //Funciones
    const getRecordings = useCallback(() => {
      console.log("recuperando grabaciones");
      if (mId === "ALL"){
        columns.current.push({dataField: "delete", text: "Borrar"});
        API.get("vcm", "/getRecordings", {
          'queryStringParameters':{
            'mId': mId,
            'cId': cId,
            'server': server,
            'svcs': '1036'
          }
        }).then(function(response){
          if (response.recordings[0]){
            var miArray = [];
            for (let index = 0; index < response.recordings[0].recording.length; index++) {
              const element = response.recordings[0].recording[index];
              var startiempo = new Date(element.startTime[0]*1);
              var endtiempo = new Date(element.endTime[0]*1);
              var starTiming = (startiempo.toDateString())+' '+('0' + startiempo.getHours()).slice(-2) + ':' + ('0' + startiempo.getMinutes()).slice(-2);
              var endTiming = (endtiempo.toDateString())+' '+('0' + endtiempo.getHours()).slice(-2) + ':' + ('0' + endtiempo.getMinutes()).slice(-2);
              const roomData = {
                recordID: element.recordID[0], 
                name: element.name[0], 
                startTime: starTiming, 
                endTime: endTiming, 
                playback: <Button variant="info" title="Reproducir" target="_blank" href={element.playback[0].format[0].url[0]}><i className="fas fa-play"></i></Button>,
                delete: <DropdownButton 
                          title={<i className="far fa-trash-alt"></i>}
                          variant="danger"
                          as={ButtonGroup}
                          >
                              <Dropdown.Header>¿Seguro?</Dropdown.Header>
                              <Dropdown.Item onClick={()=> deleteRecording(element.recordID[0],server,cId,setIncomingMsg)} as="button">Si, elimina esta grabación</Dropdown.Item>
                      </DropdownButton>
              }
              miArray.push(roomData);         
            }
            recordingsTable.current = miArray;  
            setRenderRecords(false);
          } else {
            console.log("No hay grabaciones");
            setRenderRecords(false);
          }         
        }).catch(function(error){
          console.log(error);
        });
      } else {
        API.get("vcm","/getRecordings",{
          'queryStringParameters':{
            'mId': mId,
            'svcs': '1038'
          }
        }).then((response)=>{
          if (response.recordings[0]){
            var miArray = [];
            for (let index = 0; index < response.recordings[0].recording.length; index++) {
              const element = response.recordings[0].recording[index];
              var startiempo = new Date(element.startTime[0]*1);
              var endtiempo = new Date(element.endTime[0]*1);
              var starTiming = (startiempo.toDateString())+' '+('0' + startiempo.getHours()).slice(-2) + ':' + ('0' + startiempo.getMinutes()).slice(-2);
              var endTiming = (endtiempo.toDateString())+' '+('0' + endtiempo.getHours()).slice(-2) + ':' + ('0' + endtiempo.getMinutes()).slice(-2);
              const roomData = {
                recordID: element.recordID[0], 
                name: element.name[0], 
                startTime: starTiming, 
                endTime: endTiming, 
                playback: <Button variant="info" title="Reproducir" target="_blank" href={element.playback[0].format[0].url[0]}><i className="fas fa-play"></i></Button>
              }
              miArray.push(roomData);         
            }
            recordingsTable.current = miArray;  
            setRenderRecords(false);
          } else {
            console.log("No hay grabaciones");
            setRenderRecords(false);
          }
        }).catch((err)=>{
          console.log(err);
        });
      }
    },[cId, mId, server, setIncomingMsg]);

    useEffect(()=>{
      getRecordings();
  },[getRecordings]);

    //Funciones

    if(renderRecords){
      return (<div><LoaderSpiner /></div>)
    } else {
      if (recordingsTable.current.length === 0){
        return (
          <Table striped bordered hover size = "sm">
            <thead>
              <tr>
                <th>No hay grabaciones</th>
              </tr>
            </thead>
          </Table>
        )
      }
      return (
        <div>
          <h5>Grabaciones</h5>
          <BootstrapTable 
            keyField="recordID"
            //data={recordingsTable.current[0].recording}
            data={recordingsTable.current}
            columns={columns.current}
            pagination={paginationFactory()}
          />
        </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recordings);