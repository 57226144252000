import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import { useHistory } from "react-router-dom";
import { usersApi, getUserDataApi } from "../libs/postApi_Lib";

const mapStateToProps = state => ({
    userData: state.userData
});

const mapDispatchToProps = dispatch => ({
    setIncomingMsg(incomingMsgCenter){
        dispatch({
            type: "SHOW_MSG_CENTER",
            incomingMsgCenter
        })
    }, 
    setUserData(newUserData){
        dispatch({
            type: "SAVE_USER_DATA",
            newUserData
        })
    },
});

const UserProfile = ({userData, setIncomingMsg, setUserData}) => {
    const [nombre, setNombre] = useState(userData[0].nombre);
    const [apellido, setApellido] = useState(userData[0].apellido);
    const email = userData[0].email;
    const [isLoading, setIsLoading] = useState(false);
    const [btnText, setBtnText] = useState("Guardar");
    const history = useHistory();

    const validateSubmit = () => {
        return (
            nombre.length > 0 &&
            apellido.length > 0 &&
            email.length > 0
        )
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        setBtnText("");
        let userDataUpdate = {
            userId: userData[0].userId,
            email:email,
            rol:userData[0].rol,
            nombre:nombre,
            apellido:apellido,
            cliente:userData[0].cliente
        }
        usersApi(userDataUpdate)
        .then((response) => {
            const time = new Date();
            const hour = time.getHours();
            const minute = time.getMinutes();
            const incomingMsgCenter={
                title: "Actualizar perfil",
                time: hour + ":" + minute,
                text: response.msg,
                show: true
            }
            setIncomingMsg(incomingMsgCenter);
            getUserDataApi(email)
            .then((response)=>{
                setUserData(response);
            })
            history.push("/console");
        });
    }

    /*const updateUserData = (email) => {
        API.get("vcm", "/getUserData", {
            'queryStringParameters': {
                'email': email
            }
        }).then(function (response){
            setUserData(newUserData);
            const time = new Date();
            const hour = time.getHours();
            const minute = time.getMinutes();
            const incomingMsgCenter={
                title: "Bienvenido",
                time: hour + ":" + minute,
                text: response.nombre + " " + response.apellido,
                show: true
            }
            setIncomingMsg(incomingMsgCenter);
        }).catch(function(err) {
            console.log(err);
            //Enviar notificacion a MsgCenter => no se pudo encontrar la información del usuario
        });
    }*/
    
    return (
        <Row className="justify-content-md-center">
            <Col md={8}>
                <form onSubmit={handleSubmit}>
                    <Form.Group controlId="nombre" as={Row}>
                        <label className="form-label">Nombres</label>
                        <Form.Control 
                            autoFocus
                            type="text"
                            value={nombre}
                            onChange={e => setNombre(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="apellido" as={Row}>
                        <label className="form-label">Apellidos</label>
                        <Form.Control 
                            type="text"
                            value={apellido}
                            onChange={e=> setApellido(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="email" as={Row}>
                        <label className="form-label">Correo electrónico</label>
                        <Form.Control 
                            readOnly
                            type="text"
                            value={email}
                            onChange={e=>console.log(e.target.value)}
                        />
                    </Form.Group>
                    <hr></hr>
                    <LoaderButton 
                        block
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validateSubmit()}
                    >{btnText}</LoaderButton>
                </form>
            </Col>
        </Row>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
