import React from 'react';
import './App.css';
import {Row, Col} from "react-bootstrap"
import LogoFooter from "./img/LogoFooter.png"
import Routes from "./Routes";
import { Provider } from "react-redux";
import store from "./store";
import Header from "./containers/header"

const App = () => {
  return (
    <Provider store={store}>
      <div className="App container-fluid">
        <div className="container">
          <Header /><hr></hr>

          <Routes />
          
          <hr></hr>
          <Row className="justify-content-md-end">
            <Col className="bottom-logo-container text-right mb-3">
            <a href="http://www.gvalor.co" rel="noopener noreferrer" target="_blank"><img src={LogoFooter} alt="Logo footer" className="footer-logo"></img></a>
            </Col>
          </Row>
        </div>
      </div>
    </Provider>
  );
}

export default App;
