import API from "@aws-amplify/api";
import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Form, Tabs, Tab, Button, Modal } from "react-bootstrap";
import LoaderSpinner from "../components/LoaderSpinner";
import sha1 from "sha1";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { ImEnter } from "react-icons/im";
import { RiMailSendLine } from "react-icons/ri";
import { IoIosRefresh } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const Analitics = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [tabSelected, setTabSelected] = useState("meetingRunning");
    const [isClientSelected, setIsClientSelected] = useState(false);
    const [showModalEnterRoom,setShowModalEnterRoom] = useState(false);
    const [nombreForMeeting,setNombreForMeeting] = useState("");
    const [meetIsSelected,setMeetIsSelected] = useState(false);
    const [showModalSendInvite, setShowModalSendInvite] = useState(false);
    const [showModalEndMeeting, setShowModalEndMeeting] = useState(false);
    const [modAccess, setModAccess] = useState(false);
    const [concurrencia, setConcurrencia] = useState(0);
    const [emailInvitado, setEmailInvitado] = useState("");
    const [emailCC, setEmailCC] = useState("");
    const [emailBCC, setEmailBCC] = useState("");
    const [confirmEndMeeting, setConfirmEndMeeting] = useState(false);
    const [showModalAttendies, setShowModalAttendies] = useState(false);
    const [isLoadingAttendies, setIsLoadingAttendies] = useState(false);
    
    const clientsList = useRef([]);
    const clientSelected = useRef({});
    const isFirstRender = useRef(true);
    const meetingsRunnig = useRef([]);
    const bootstrapTableMeetsRunnMsg = useRef("No hay datos que mostrar");
    const meetingSelected = useRef({});
    const attendeesTable = useRef([]);

    const meetingsRunningColumns = useRef([
        {
            dataField: 'meetingName',
            text: 'Sala',
            style:{
                textAlign: "left"
            }
        },
        {
            dataField:'participants',
            text: 'Participantes',
            headerStyle: () => {
                return { width: '15%' }
            },
        }
    ]);

    const attendeesTableColumns = useRef([
        {
            dataField: 'fullName',
            text: 'Nombre',
            style:{
                textAlign: "left"
            }
        },
        {
            dataField:'role',
            text: 'Rol',
            style:{
                textAlign: "right"
            },
            headerStyle: () => {
                return { textAlign: 'right' }
            },
        }
    ]);

    useEffect(()=>{
        
        const onload = async() => {
            if(isFirstRender.current){
                console.log("Actualizando lista clientes");
                const response = await API.get("vcm", "/getClients");
                clientsList.current = response;
                isFirstRender.current = false;
                setIsLoading(false);
            }
        }
        onload()
    },[])

    const handleClientSelected = (index) => {
        setIsClientSelected(true);
        clientSelected.current = clientsList.current[index];
        renderTab();
    }

    const handleTabSelected = (tab) => {
        setIsLoadingTab(true);
        setTabSelected(tab);
    }

    const hanldeShowParticipants = (index) => {
        setMeetIsSelected(true);
        meetingSelected.current = meetingsRunnig.current[index];
        attendeesTable.current = [];
        setShowModalAttendies(true);
        setIsLoadingAttendies(true);
        const attendees = meetingSelected.current.attendees;
        if(attendees){
            for (let index = 0; index < attendees.length; index++) {
                const element = attendees[index];
                const attendeesData = {
                    userID: element.userID,
                    fullName: element.fullName[0],
                    role: element.role[0] === "VIEWER" ? "Participante" : "Moderador",
                }
                attendeesTable.current.push(attendeesData);
            }
            setIsLoadingAttendies(false);
        }
    }

    const renderTab = async() => {
        setIsLoadingTab(true);
        meetingsRunnig.current = [];
        meetingSelected.current = {};
        setMeetIsSelected(false);
        setConcurrencia(0);
        const server = clientSelected.current.servidores[0].servidor;
        const secret = clientSelected.current.servidores[0].BBB_Secret;
        
        switch (tabSelected) {
            case "meetingRunning":
                console.log("Obteniendo salas en ejecución para este cliente");
                const apiEndPoint = "getMeetings";
                const hash = sha1(apiEndPoint+secret);
                const request = server+"api/"+apiEndPoint+"?checksum="+hash;
                const axios = require('axios');
                const response = await axios.get(request);
                if(response.status === 200){
                    const data = response.data;
                    var parseString = require('xml2js').parseString;
                    parseString(data, async function(err, result){
                        if(err){
                            console.log(err);
                        } else {
                            //console.log(result);
                            const respond = result.response.returncode[0];
                            if (respond === "SUCCESS"){
                                const meetings = result.response.meetings;
                                if(meetings[0].length === 0){
                                    console.log(result.response.message[0])
                                } else {
                                    const meet = meetings[0].meeting;
                                    var concurridos = 0
                                    for (let index = 0; index < meet.length; index++) {
                                        const element = meet[index];
                                        const meetingData = {
                                            attendeePW: element.attendeePW[0],
                                            attendees: element.attendees[0].attendee,
                                            participants: (element.attendees[0].attendee ? 
                                                <Button variant="link" onClick={()=>hanldeShowParticipants(index)}>{element.attendees[0].attendee.length}</Button> : 0),
                                            createDate: element.createDate[0],
                                            hasUserJoined: element.hasUserJoined[0],
                                            meetingID: element.meetingID[0],
                                            meetingName: element.meetingName[0],
                                            moderatorPW: element.moderatorPW[0],
                                        }
                                        meetingsRunnig.current.push(meetingData);
                                        concurridos = concurridos + (element.attendees[0].attendee ? element.attendees[0].attendee.length : 0);
                                    }
                                    setConcurrencia(concurridos)
                                }
                                //console.log(meetingsRunnig.current);
                            } else {
                                console.log(respond);
                            }
                        }  
                        setIsLoadingTab(false);                      
                    });
                } else {
                    console.log("Error en la peticion");
                    setIsLoadingTab(false);
                }

                break;
            default:
                break;
        }
    }

    const handleOnSelect = (row, isSelect) => {
        if(isSelect){
            meetingSelected.current = row;
            setMeetIsSelected(true);
        } else {
            meetingSelected.current = {};
            setMeetIsSelected(false);
        }
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        onSelect: handleOnSelect,
    };

    const handleEnterRoom = () => {
        setShowModalEnterRoom(false);
        setIsLoading(true);
        const server = clientSelected.current.servidores[0].servidor;
        const secret = clientSelected.current.servidores[0].BBB_Secret;
        const ApiEndPoint = "join";
        const formatFullName = nombreForMeeting.trim().split(' ');
        for (let index = 0; index < formatFullName.length; index++) {
            formatFullName[index] = encodeURIComponent(formatFullName[index]);
        }
        const fullName = formatFullName.join('+');
        var joinRequest = "fullName="+fullName;
        joinRequest = joinRequest+"&meetingID="+meetingSelected.current.meetingID;
        if(modAccess){
            joinRequest = joinRequest+"&password="+encodeURIComponent(meetingSelected.current.moderatorPW);
        } else {
            joinRequest = joinRequest+"&password="+encodeURIComponent(meetingSelected.current.attendeePW);
        }
        joinRequest = joinRequest+"&redirect=true";
        const hash = sha1(ApiEndPoint+joinRequest+secret);
        //window.location.href = (server+"api/"+ApiEndPoint+"?"+joinRequest+"&checksum="+hash,"_blank");
        //console.log(server+"api/"+ApiEndPoint+"?"+joinRequest+"&checksum="+hash);
        window.open(server+"api/"+ApiEndPoint+"?"+joinRequest+"&checksum="+hash, '_blank');
        setIsLoading(false);
    }

    const handleDisabledAccessMeeting = () => {
        return nombreForMeeting.length > 3
    }
    const handleDisabledSendInvite = () => {
        return emailInvitado.length > 3 && emailInvitado.includes("@");
    }

    const handleSendInvite = async() => {
        setShowModalSendInvite(false);
        const server = clientSelected.current.servidores[0].servidor;
        const secret = clientSelected.current.servidores[0].BBB_Secret;
        const ApiEndPoint = "join";
        var invitados = emailInvitado.split(",");
        var ccs = [];
        var bccs = [];
        if(emailCC.length > 0 && emailCC.includes("@")){
            ccs = emailCC.split(",");
        }
        if(emailBCC.length > 0 && emailBCC.includes("@")){
            bccs = emailBCC.split(",");
        }
        for (let index = 0; index < invitados.length; index++) {
            invitados[index] = invitados[index].trim();
        }
        for (let index = 0; index < ccs.length; index++) {
            ccs[index] = ccs[index].trim();
        }
        for (let index = 0; index < bccs.length; index++) {
            bccs[index] = bccs[index].trim();
        }

        const formatFullName = nombreForMeeting.trim().split(' ');
        for (let index = 0; index < formatFullName.length; index++) {
            formatFullName[index] = encodeURIComponent(formatFullName[index]);
        }
        const fullName = formatFullName.join('+');
        
        var joinRequest = "fullName="+fullName;
        joinRequest = joinRequest+"&meetingID="+meetingSelected.current.meetingID;
        joinRequest = joinRequest+"&password="+encodeURIComponent(meetingSelected.current.attendeePW);
        joinRequest = joinRequest+"&redirect=true";
        const hash = sha1(ApiEndPoint+joinRequest+secret);
        //window.location.href = (server+"api/"+ApiEndPoint+"?"+joinRequest+"&checksum="+hash,"_blank");
        const urlJoin = server+"api/"+ApiEndPoint+"?"+joinRequest+"&checksum="+hash;
        const msg = `
            Hola!<br><br>
            Has sido invitad@ a participar en una sesión vroom, por favor haz clic en el siguiente
            enlace para acceder a la sesión:<br><br>
            ${urlJoin}<br><br>
            Si no puedes acceder con el enlace anterior, por favor copialo y 
            pegalo directamente en tu navegador.<br><br>
            Saludos!
        `;
        try {
            const response = await API.post("vcm", "/mailer", {
                body: {
                    TO: invitados,
                    CC: ccs,
                    BCC: bccs,
                    asunto: "Invitación a vroom | GVIS",
                    message: msg,
                }
            });
            setEmailInvitado("");
            setEmailCC("");
            setEmailBCC("");
            setNombreForMeeting("");
            console.log(response);
        } catch(error){
            console.log(error);
        }
    }

    const handleEndMeeting = async() => {
        setShowModalEndMeeting(false);
        setIsLoading(true);
        const server = clientSelected.current.servidores[0].servidor;
        const secret = clientSelected.current.servidores[0].BBB_Secret;
        const ApiEndPoint = "end";
        var joinRequest = "meetingID="+meetingSelected.current.meetingID;
        joinRequest = joinRequest+"&password="+meetingSelected.current.moderatorPW;
        const hash = sha1(ApiEndPoint+joinRequest+secret);
        try {
            const request = server+"api/"+ApiEndPoint+"?"+joinRequest+"&checksum="+hash;
            const axios = require('axios');
            const response = await axios.get(request);
            console.log(response);
            setConfirmEndMeeting(false);
            setIsLoading(false);
            renderTab();
        } catch(error){
            console.log(error);
        }
    }

    if(isLoading){
        return <LoaderSpinner />
    } else {
        return (
            <div>
                <Modal 
                    show={showModalAttendies}
                    onHide={()=>setShowModalAttendies(false)}
                >
                    <Modal.Header closeButton>Participantes</Modal.Header>
                    <Modal.Body>
                        {isLoadingAttendies ? 
                            <Row>
                                <Col>
                                    <LoaderSpinner />
                                </Col>
                            </Row>
                        : 
                            <Row>
                                <Col>
                                    <BootstrapTable 
                                        keyField='userID'
                                        data={attendeesTable.current}
                                        columns={attendeesTableColumns.current}
                                        striped
                                        hover
                                        noDataIndication={bootstrapTableMeetsRunnMsg.current}
                                        pagination={paginationFactory(5)}
                                    />
                                </Col>
                            </Row>
                        }
                    </Modal.Body>
                </Modal>
                <Modal 
                    show={showModalEndMeeting}
                    onHide={()=>setShowModalEndMeeting(false)}
                >
                    <Modal.Header closeButton>Finalizar sesión</Modal.Header>
                    <Modal.Body>
                        <Row className="mb-2">
                            <Col className="text-center">
                                <h4>¿Esta seguro?, todos los participantes serán expulsados de la sala.</h4>
                            </Col>
                        </Row>
                        <Form.Group controlId="endCheckbox">
                            <Form.Check 
                                type="checkbox" 
                                label="Si, finaliza la sesión y expulsa a todos los participantes" 
                                onChange={()=>setConfirmEndMeeting(!confirmEndMeeting)}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                            variant="danger" 
                            disabled={!confirmEndMeeting} 
                            onClick={()=>handleEndMeeting()}
                        >Finalizar</Button>
                    </Modal.Footer>
                </Modal>
                <Modal 
                    show={showModalSendInvite}
                    onHide={()=>setShowModalSendInvite(false)}
                >
                    <Modal.Header closeButton>Invitar a la sala</Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="nombreInvitado">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Ingresa el nombre del invitado" 
                                value={nombreForMeeting}
                                onChange={e=>setNombreForMeeting(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                Con este nombre reconoceran al invitado los demás participantes de la conferencia
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="emailInvitado">
                            <Form.Label>Email invitado</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Ingresar email" 
                                value={emailInvitado}
                                onChange={e=>setEmailInvitado(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                Correo electrónico del invitado, en este correo recibirá el enlace de acceso (puede separar con comas varias direcciones de correo).
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="emailInvitadoCC">
                            <Form.Label>Copia invitación</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Ingresar email" 
                                value={emailCC}
                                onChange={e=>setEmailCC(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                Enviar copia de la invitación a alguien más (puede separar con comas varias direcciones de correo)
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="emailInvitadoBCC">
                            <Form.Label>Copia oculta invitación</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Ingresar email" 
                                value={emailBCC}
                                onChange={e=>setEmailBCC(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                Enviar copia oculta de la invitación (puede separar con comas varias direcciones de correo)
                            </Form.Text>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" disabled={!handleDisabledSendInvite()} onClick={()=>handleSendInvite()}>Ingresar</Button>
                    </Modal.Footer>
                </Modal>
                <Modal 
                    show={showModalEnterRoom}
                    onHide={()=>setShowModalEnterRoom(false)}
                >
                    <Modal.Header closeButton>Ingresar a la sala</Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="enterRoom">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Ingresa tu nombre" 
                                value={nombreForMeeting}
                                onChange={e=>setNombreForMeeting(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                Con este nombre te reconoceran los demás participantes de la conferencia
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="rolCheckbox">
                            <Form.Check type="checkbox" label="Ingresar como moderador" onChange={()=>setModAccess(!modAccess)}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" disabled={!handleDisabledAccessMeeting()} onClick={()=>handleEnterRoom()}>Ingresar</Button>
                    </Modal.Footer>
                </Modal>
                <Row >
                    <Col sm={"auto"} className="text-right">
                        <Form.Label column >Cliente:</Form.Label>
                    </Col>
                    <Col> 
                        <Form.Control as="select"
                            onChange={e=>handleClientSelected(e.target.value)}
                        >
                            <option>Seleccione...</option>
                            {clientsList.current.map((x,i)=>{
                                return (
                                    <option value={i} key={x.id_tributaria}>{x.id_tributaria + " | " + x.nombre}</option>
                                )
                            })}
                        </Form.Control>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-3">
                        {isClientSelected ? 
                            <Tabs 
                                id="tableMenu"
                                activeKey = {tabSelected}
                                onSelect={(k) => handleTabSelected(k)}
                            >
                                <Tab eventKey="meetingRunning" title="Salas en ejecución">
                                    {isLoadingTab ?
                                        <LoaderSpinner />
                                    :
                                        <div>
                                            <Row className="mt-2 mb-2">
                                                <Col className="text-right">
                                                    <Button className="mr-1" title="Ingresar a la sala" variant="primary" onClick={()=>setShowModalEnterRoom(true)} disabled={!meetIsSelected}><ImEnter /></Button>
                                                    <Button className="mr-1" title="Invitar a la sala" variant="success" onClick={()=>setShowModalSendInvite(true)} disabled={!meetIsSelected}><RiMailSendLine /></Button>
                                                    <Button className="mr-1" title="Actualizar" variant="info" onClick={()=>renderTab()}><IoIosRefresh /></Button>
                                                    <Button className="mr-1" title="Actualizar" variant="danger" onClick={()=>setShowModalEndMeeting(true)} disabled={!meetIsSelected}><IoClose /></Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <BootstrapTable 
                                                        keyField='meetingID'
                                                        data={meetingsRunnig.current}
                                                        columns={meetingsRunningColumns.current}
                                                        striped
                                                        hover
                                                        noDataIndication={bootstrapTableMeetsRunnMsg.current}
                                                        pagination={paginationFactory()}
                                                        selectRow={selectRow}
                                                    />
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <Row>
                                                <Col className="text-left">
                                                    <h4>Total concurrencia: {concurrencia}</h4>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </Tab>
                            </Tabs>
                        :
                            <div></div>
                        }
                    </Col>
                </Row>
            </div>
        )
    }
};

export default Analitics;