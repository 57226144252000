import React, { useState } from "react";
import "../containers/join.css";
import { Row, Col, Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { API } from "aws-amplify";

function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");
  
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);
  
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return "";
    }
  
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  function callApiJoin(meetingData){
    API.get("vcm", "/joinMeeting", {
        'queryStringParameters': {
            'meetingID': meetingData.meetingID,
            'clienteId': meetingData.clienteId,
            'password': meetingData.password,
            'fullName': meetingData.fullName
        }
    }).then(function(response){
        //
        if (response.result==="SUCCESS"){
            window.location.href = response.urlToJoin;
        } else {
            //Notificar a MSGCenter el mensaje de error que viene en la respuesta
            console.log(response);
        }
      }).catch(function(err){
        console.log(err);
      });
  }


const Join = () => {

    const [fullName, setFullName] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [textButton, setTextButton] = useState("Entrar a la sesión")

    function validarNombre(){
        return fullName.length > 3
    }

    function handleSubmit(event){
        event.preventDefault();

        setTextButton("");
        setIsLoading(true);
        const mId = querystring("mId");
        const pswd = querystring("pswd");
        const cId = querystring("cId");
        //hacer una verificacion al mId, cId y pswd, si no vienen en la url el MsgCenter debe dar alguna alerta o notificación

        const formatFullName = fullName.trim().split(' ');
        for (let index = 0; index < formatFullName.length; index++) {
            formatFullName[index] = encodeURIComponent(formatFullName[index]);
        }
        const fullNm = formatFullName.join('+');

        const meetingData = {
            meetingID: mId,
            password: pswd,
            fullName: fullNm,
            clienteId: cId
        }
        callApiJoin(meetingData);
    }

    return (
        <Row className="justify-content-md-center">
            <Col xs={"auto"} className="enter-container">
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="fullName">
                        <Form.Label><h3>Ingresa tu nombre</h3></Form.Label>
                        <Form.Control 
                            autoFocus
                            type ="text"
                            placeholder = "Nombre completo"
                            value={fullName}
                            onChange={e=>setFullName(e.target.value)}
                            />
                        <Form.Text className="text-muted">
                            Con este nombre te reconoceran los demás participantes de la conferencia
                        </Form.Text>
                        <LoaderButton className="enter-button"
                           block
                           type="submit"
                           isLoading={isLoading} 
                           disabled={!validarNombre()}>
                               {textButton}
                        </LoaderButton>
                    </Form.Group>
                </Form>
            </Col>
        </Row>
    );
}

export default Join;