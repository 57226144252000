import React, { useState } from "react";
import { Row, Col, Form, Alert, Button } from "react-bootstrap";
import LoadingButton from "../components/LoaderButton";
import useFormFields from "../components/formFields";
import "./registrarse.css";
import { Auth, API } from "aws-amplify"
import { useHistory } from "react-router-dom";

export default function Registrarse(){
    const [formFields, setFormField] = useFormFields({
        email: "",
        password: "",
        confirmPass: "",
        confirmCode: "",
    });
    const [nuevoRegistro, setNuevoRegistro] = useState(null);
    const [textButton, setTextButton] = useState("Registrarse");
    const [textButton2, setTextButton2] = useState("Confirmar");
    const [isLoading, setIsLoading] = useState(false);
    const [alertVariant, setAlertVariant] = useState("");
    const [alertShow, setAlertShow] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [enableBtnResendCode, setEnableBtnResendCode] = useState(false);
    const history = useHistory();

    function validarRegistro(){
        return (
            formFields.email.length > 0 &&
            formFields.password.length >0 &&
            formFields.confirmPass === formFields.password
        );
    }

    function validarConfirmacion(){
        return formFields.confirmCode.length > 0;
    }

    async function handleRegisterSubmit(event){
        event.preventDefault();
        setAlertShow(false);
        setIsLoading(true);
        setTextButton("");
        try{
            const newUser = await Auth.signUp({
                username: formFields.email,
                password: formFields.password,
              });
              setIsLoading(false);
              setNuevoRegistro(newUser);
              console.log(newUser);
        } catch (error){
            console.log(error);
            handleErrors(error);
            setIsLoading(false);        
        }
        setTextButton("Intentar de nuevo");
    }

    const handleIngresarCofnirmacion = () => {
        setAlertShow(false);
        setNuevoRegistro(true);
    }

    const handleErrors = (exception) => {
        switch (exception.code) {
            case "InvalidParameterException":
                if(exception.message === "User is already confirmed."){
                    console.log("usuario confirmado");
                    history.push("/login");
                } else {
                    setAlertMSG("La contraseña ingresada no es segura, debe tener al menos 6 caracteres, mayúsculas, minúsculas, numeros y caracteres especiales (.*#)");
                    setAlertVariant("warning");
                    setAlertShow(true);
                }
                break;
            case "InvalidPasswordException":
                setAlertMSG("La contraseña ingresada no es segura, debe tener al menos 6 caracteres, mayúsculas, minúsculas, numeros y caracteres especiales (.*#)");
                setAlertVariant("warning");
                setAlertShow(true);
                break;
            case "CodeMismatchException":
                setAlertMSG("El codigo ingresado no corresponde");
                setAlertVariant("danger");
                setAlertShow(true);
                break;
            case "UsernameExistsException":
                const msgButton = ["Este usuario ya se encuentra registrado, quieres ",  
                    <Button onClick={()=>handleIngresarCofnirmacion()} variant="link" key="01">¿ingresar codigo de confirmación?</Button>]
                setAlertMSG(msgButton);
                setAlertVariant("danger");
                setAlertShow(true);
                break;
            case "NotAuthorizedException":
                if(exception.message === "User cannot be confirmed. Current status is CONFIRMED"){
                    console.log("usuario confirmado");
                    history.push("/login");
                } else {
                    setAlertMSG("El usuario o la clave ingresada no es correcta");
                    setAlertVariant("danger");
                    setAlertShow(true);
                }
                break;
            case "LimitExceededException":
                setAlertMSG("Has alcanzado el numero de intentos permitidos, intenta de nuevo mañana");
                setAlertVariant("warning");
                setAlertShow(true);
                break;
            default:
                break;
        }
    }

    async function saveUser(user){
        return API.post("vcm", "/createUsers", {
            body: user
          });
    }

    async function handleConfirmSubmit(event){
        event.preventDefault();
        setAlertShow(false);
        setIsLoading(true);
        setTextButton2("");
        try {
            await Auth.confirmSignUp(formFields.email, formFields.confirmCode);
            await Auth.signIn(formFields.email, formFields.password);
            try{
                //Adicionar en dinamoDB.usuarios
                let user = {
                    email:formFields.email,
                    rol:"guest",
                    nombre:"",
                    apellido:"",
                    zona:"",
                    cliente:""
                }
                await saveUser(user);
                console.log("Bienvenido");
                history.push("/login");
                //Enviar a redux actualizacion de estado con usario autenticado, su rol, ID, y nombres
                //Enviar a pagina de manage -> History push
            } catch (error){
                handleErrors(error);
                console.log(error);
            }
            
            setIsLoading(false);
        } catch (error){
            console.log(error);
            handleErrors(error);
            setIsLoading(false);
        }
        setTextButton2("De nuevo");
    }

    function formRegistro (){
        return  (
            <Col md={4}>
                <form onSubmit={handleRegisterSubmit}>
                    <Form.Group controlId="email">
                        <label className="form-label">Email</label>
                        <Form.Control
                            autoFocus
                            type="email"
                            value={formFields.email}
                            onChange={setFormField}
                        />
                    </Form.Group>
                    <Form.Group controlId="password">
                        <label className="form-label">Clave</label>
                        <Form.Control
                            type="password"
                            onChange={setFormField}
                            value={formFields.password}
                            
                        />
                    </Form.Group>
                    <Form.Group controlId="confirmPass">
                        <label className="form-label">Confirmar Clave</label>
                        <Form.Control
                            type="password"
                            value={formFields.confirmPass}
                            onChange={setFormField}
                        />
                    </Form.Group>
                    <LoadingButton 
                        block
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validarRegistro()}
                    > {textButton}
                    </LoadingButton>
                </form>
            </Col>
        );
    }

    const handleResendCode = () => {
        setIsLoading(true);
        setAlertShow(false);
        setEnableBtnResendCode(true);
        Auth.resendSignUp(formFields.email)
        .then((value)=>{
            console.log(value);
            setIsLoading(false);
        }).catch(error=>{
            console.log(error);
            handleErrors(error);
        })
    }

    function formConfirmRegistro (){
        return  (
            <Col md={4}>
                <form onSubmit={handleConfirmSubmit}>
                    <Form.Group controlId="confirmCode">
                        <label className="form-label">Codigo de confirmación</label>
                        <Form.Control
                            autoFocus
                            type="tel"
                            value={formFields.confirmCode}
                            onChange={setFormField}
                        />
                        <Form.Text className="text-muted">
                            Verifica en tu correo electrónico el codigo de confirmación.
                        </Form.Text>
                    </Form.Group>
                    <LoadingButton 
                        block
                        variant="success"
                        onClick={() => handleResendCode()}
                        isLoading={isLoading}
                        disabled={enableBtnResendCode}
                    > Re-enviar codigo
                    </LoadingButton>
                    <LoadingButton 
                        block
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validarConfirmacion()}
                    > {textButton2}
                    </LoadingButton>
                </form>
            </Col>
        );
    }

    return(
        <div className="registro-container">
            <Row className="justify-content-md-center">
                {nuevoRegistro === null ? formRegistro() : formConfirmRegistro()}            
            </Row>
            <Row className="justify-content-center mt-3">
                <Col>
                    <Alert variant={alertVariant} show={alertShow} onClose={() => setAlertShow(false)} dismissible >
                        {alertMSG}
                    </Alert>
                </Col>
            </Row>
        </div>
    );
}