const dev = {
  s3: {
    REGION: "us-east-2",
    BUCKET: "api-vcm-dev-vcmbucket-8q9pwj3kl9jo"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://vojrw65n1e.execute-api.us-east-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_bLo7f1ny3",
    APP_CLIENT_ID: "2t1cj5r3e3o219ffej9fq18g8k",
    IDENTITY_POOL_ID: "us-east-2:fc3c407f-41ad-4809-ba31-ad45a513429b"
  }
};

const prod = {
  s3: {
    REGION: "us-east-2",
    BUCKET: "api-vcm-prod-vcmbucket-1f4qjixh076le"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://k7nm89w8i0.execute-api.us-east-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_FkQVxJ0i5",
    APP_CLIENT_ID: "7m9irinsc121lemno9ob87dhp1",
    IDENTITY_POOL_ID: "us-east-2:86cc0f3d-00dd-40d7-ba93-f0744a53b8dc"
  }
};

// Si no se especifica el ambiente (dev ó prod), se asume que el ambiente es dev
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Aca las configuraciones para ambos ambientes
  ...config
};