import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form , Table, Dropdown, DropdownButton, Button, ButtonGroup } from "react-bootstrap";
import LoaderSpinner from "./LoaderSpinner";
import { connect } from "react-redux";
import { getRooms } from "../libs/postApi_Lib";
import RoomsDataForm from "./roomsDataForm";
import "./roomsEdit.css"


const mapStateToProps = state => ({
    clientData: state.clientData,
    roomsData: state.roomsData
});

const mapDispatchToProps = dispatch => ({
    setRoomsData (updateRoomsData){
        dispatch({
            type: "SET_ROOMS_DATA",
            updateRoomsData
        })
    },
    setIncomingMsg(incomingMsgCenter){
        dispatch({
            type: "SHOW_MSG_CENTER",
            incomingMsgCenter
        })
    }
});

const RoomsEdit = ({clientData, roomsData, setRoomsData, setIncomingMsg}) => {
    const [updateRoomsTable, setUpdateRoomsTable] = useState(false);
    const [okToRender, setOkToRender] = useState(false);
    var clientList = useRef([]);
    const [roomSelected, setRoomSelected] = useState(false);
    const [selectedRoomData, setSelectedRoomData] = useState("");
    const clientSelected = useRef("");
    const lastCLientSelected = useRef("");

    //Functions

    useEffect(()=>{
        var extractClientList = clientList.current;
        extractClientList.splice(0,clientList.length);
        for (let i = 0; i < clientData.length; i++) {
            const element = clientData[i];
            const client = {
                clientId: element.clientId,
                nombre: element.nombre,
                id_tributaria: element.id_tributaria
            };
            extractClientList.push(client);
        }
        clientList.current = extractClientList;
        setOkToRender(true);
    },[clientData,clientList])

    const handleClientChange = (index) => {
        if (index !== "Seleccione..."){
            lastCLientSelected.current = index;
            setUpdateRoomsTable(false)
            getRooms(clientList.current[index].clientId)
            .then((response)=>{
                let msgR = response[0].msg;
                if (msgR && msgR.includes("Error")) {
                    const time = new Date();
                    const hour = time.getHours();
                    const minute = time.getMinutes();
                    const incomingMsgCenter = {
                        title: "Rooms",
                        time: hour + ":" + minute,
                        text: msgR,
                        show: true
                    };
                    setIncomingMsg(incomingMsgCenter);
                } else {
                    setRoomsData(response);
                    setUpdateRoomsTable(true);
                }
            });
        } else {
            setUpdateRoomsTable(false);
        }
    }
    
    const editRoom = (roomIndex) => {
        setSelectedRoomData(roomsData[roomIndex])
        setRoomSelected(true);
    }

    const deleteRoom = (roomId) => {
        console.log(roomId)
        console.log("No tiene permisos para realizar esta acción");
    }

    const decodeUri = (txt) => {
        return decodeURIComponent(txt);
    }

    const copyToClipboard = (txt) => {
        //var txtToCopy = txt;
        var copyText = document.querySelector("#clipBoardContainer");
        copyText.value=txt;
        copyText.select();
        try {
            document.execCommand("copy");
            const time = new Date();
            const hour = time.getHours();
            const minute = time.getMinutes();
            const incomingMsgCenter = {
                title: "Rooms",
                time: hour + ":" + minute,
                text: "Copiado al portapapeles",
                show: true
            };
            setIncomingMsg(incomingMsgCenter);
        } catch (error){
            const time = new Date();
            const hour = time.getHours();
            const minute = time.getMinutes();
            const incomingMsgCenter = {
                title: "Rooms",
                time: hour + ":" + minute,
                text: "Error copiando " + error,
                show: true
            };
            setIncomingMsg(incomingMsgCenter);
        }
    }

    const showAllRecord = () => {
        if (clientSelected.current){
            var clientServers = "";
            for (let index = 0; index < clientData.length; index++) {
                const element = clientData[index];
                if (element.clientId === clientSelected.current){
                    clientServers = element.servidores;
                }    
            }
            if (clientServers.length === 0) {
                const time = new Date();
                const hour = time.getHours();
                const minute = time.getMinutes();
                const incomingMsgCenter = {
                    title: "Rooms",
                    time: hour + ":" + minute,
                    text: "Este cliente no tiene servidores vroom",
                    show: true
                };
                setIncomingMsg(incomingMsgCenter);
            } else {
                if (clientServers.length <= 1) {
                    let mId = "ALL";
                    let cId = clientSelected.current;
                    let server = clientServers[0].servidor;
                    const recordsUrl = "/recordings?mId="+mId+"&cId="+cId+"&server="+server;
                    window.open(recordsUrl, '_blank');
                } else {
                    //Implementar Modal donde se muestre los servidores del cliente, y se solicite selecionar el sevidor
                    //para el cual se desean ver todas las grabaciones
                }
            }
        } else {
            const time = new Date();
            const hour = time.getHours();
            const minute = time.getMinutes();
            const incomingMsgCenter = {
                title: "Rooms",
                time: hour + ":" + minute,
                text: "No existen salas para este cliente",
                show: true
            };
            setIncomingMsg(incomingMsgCenter);
        }
    }

    //Render
    if(okToRender){
        if (roomSelected){
            return (<RoomsDataForm roomSelected={true} roomData={selectedRoomData} setRoomSelected={setRoomSelected}/>)
        } else {
            return (
                <div>
                    <div className="mt-3">
                        <Form>
                            <Form.Group as={Row} controlId="clientSelect" >
                                <Form.Label column sm="2">Cliente</Form.Label>
                                <Col sm="8">
                                    <Form.Control 
                                        type="text"
                                        as="select"
                                        onChange={e=>handleClientChange(e.target.value)}
                                    >
                                        <option>Seleccione...</option>
                                        {clientList.current.map((v,i)=>{
                                            return (<option key={i} value={i} label={v.id_tributaria + " | " + v.nombre}></option>);
                                        })}
                                    </Form.Control>
                                </Col>
                                <Col sm={2}>
                                    <Button onClick={()=>handleClientChange(lastCLientSelected.current)} title="Recargar"><i className="fas fa-sync-alt"></i></Button>{' '}
                                    <Button variant="info" onClick={()=>showAllRecord()} title="Todas las grabaciones"><i className="fas fa-compact-disc"></i></Button>
                                </Col>
                            </Form.Group>
                        </Form>
                        <hr></hr>
                    </div>
                    <div>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Sala</th>
                                </tr>
                            </thead>
                            <tbody>
                                {updateRoomsTable && roomsData.map((v,i)=>{
                                    clientSelected.current = v.clienteId; 
                                    if (v.msg){
                                        return (<tr key={i}><td>{v.msg}</td></tr>)
                                    } else {
                                        return (
                                            <tr key={i}>
                                                <td>{decodeUri(v.roomName)}</td>
                                                <td><Dropdown as={ButtonGroup}>
                                                        <Button variant="info" title="Ingresar como moderador" target="_blank" href={v.urlAccesoModerador}><i className="fas fa-user-tie"></i></Button>
                                                        <Dropdown.Toggle title="copiar vinculos" split variant="info" id="ModsUrls"/>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => copyToClipboard(v.urlAccesoModerador)}>Copiar url</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => copyToClipboard(v.tynyUrlAccesoModerador)}>Copiar url (tiny)</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>{' '}
                                                    <Dropdown as={ButtonGroup}>
                                                        <Button variant="info" title="Ingresar como participante" target="_blank" href={v.urlAccesoParticipantes}><i className="fas fa-user"></i></Button>
                                                        <Dropdown.Toggle title="copiar vinculos" split variant="info" id="PartsUrls"/>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => copyToClipboard(v.urlAccesoParticipantes)}>Copiar url</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => copyToClipboard(v.tynyUrlAccesoParticipantes)}>Copiar url (tiny)</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>{' '}
                                                    <Button variant="info" title="Grabaciones" target="_blank" href={v.urlTablaGrabaciones}><i className="fas fa-play"></i></Button>{' '}
                                                    <Button variant="success" title="Editar" onClick={() => editRoom(i)}><i className="far fa-edit"></i></Button>{' '}
                                                    <DropdownButton 
                                                        title={<i className="far fa-trash-alt"></i>}
                                                        variant="danger"
                                                        as={ButtonGroup}
                                                        >
                                                            <Dropdown.Header>¿Seguro?</Dropdown.Header>
                                                            <Dropdown.Item onClick={()=> deleteRoom(v.roomId)} as="button">Si, elimina este usuario</Dropdown.Item>
                                                    </DropdownButton>
                                                </td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <input
                            readOnly
                            id = "clipBoardContainer"
                            className = "hiddenArea"
                        />
                </div>
            );
        }
    } else {
        return (<LoaderSpinner />)
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RoomsEdit);