import { API } from "aws-amplify";

export const PostApi = (data, updateFlag) => {
    if (updateFlag){
        return new Promise ((resolve)=>{
            API.put("vcm", "/updateClient", {
                body: data
            }).catch(error => {
                console.log(error);
                return ({
                    'msg':"Error almacenando datos del cliente"
                })
            }).then((value)=>{
                console.log(value.msg);
                resolve(value);
                //resolve devuelve el resultado de esta promise
            });
        })
    } else {
        return new Promise((resolve) =>{
            API.post("vcm", "/createClients", {
                body: data
            }).catch(error => {
                console.log(error);
                return ({
                    'msg':"Error creando nuevo cliente"
                })
            }).then((value)=>{
                console.log(value.msg);
                resolve(value);
            });
        })
    }
}

export const usersApi = (data) => {
    return new Promise ((resolve) => {
        API.put("vcm","/updateUser", {
            body:data
        }).catch(error => {
            console.log(error);
            return ({"msg":"Error actualizando datos del usuario"});
        }).then((value) => {
            console.log(value.msg);
            resolve(value);
        })
    })
}

export const createRoomApi = (data) => {
    return new Promise ((resolve)=>{
        API.post("vcm", "/createRooms", {
            body: data
        }).catch(error => {
            console.log(error);
            return ({"msg":"Error salvando datos de la sala"});
        }).then((value)=>{
            console.log(value.msg);
            resolve(value);
        })
    })
}

export const updateRoomApi = (data) => {
    return new Promise ((resolve) => {
        API.put("vcm","/updateRoom", {
            body:data
        }).catch(error => {
            console.log(error);
            return ({"msg":"Error actualizando sala"});
        }).then((value) => {
            console.log(value.msg);
            resolve(value);
        })
    })
}

export const getRooms = (clientId) => {
    return new Promise((resolve) => {
        API.get("vcm", "/getRooms", {
            'queryStringParameters': {
                'clientId' : clientId
            }
        }).catch(error => {
            console.log(error);
            return ([{"msg":"Error recuperando salas del cliente"}]);
        }).then((response) => {
            resolve(response);
        })
    })
}

export const getUserDataApi = (email) => {
    return new Promise((resolve) => {
        API.get("vcm", "/getUserData", {
            'queryStringParameters': {
                'email': email
            }
        }).catch(error => {
            console.log(error);
            return ({"msg":"Error obteniendo datos actualizados del usuario"});
        }).then((response) => {
            const newUserData = {
                userData: [{
                    userId: response.userId,
                    nombre: response.nombre,
                    apellido: response.apellido,
                    email: response.email,
                    rol: response.rol,
                    cliente: response.cliente,
                    zona: response.zona
                }],
            }
            resolve(newUserData);
        })
    })
}

export const deleteRecordingApi = (data) => {
    return new Promise ((resolve)=>{
        API.post("vcm", "/deleteRecording", {
            body: data
        }).catch(error => {
            console.log(error);
            return ({"msg":"Error eliminando grabación"});
        }).then((value)=>{
            console.log(value.msg);
            resolve(value);
        })
    })
}