import React from "react";
import { Spinner } from "react-bootstrap";
import "./LoaderSpinner.css";

export default function LoaderSpinner({
  isLoading,
  className = "",
  ...props
}) {
  return (
    <Spinner
        animation="border"
        variant="primary"
        className={`loader-spinner ${className}`}
        {...props}
    >
        {props.children}
    </Spinner>
  );
}