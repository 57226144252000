import React from "react";
import RoomsDataForm from "./roomsDataForm";

export default function RoomsCreate(){
    
    return (       
        <div>
            
            <RoomsDataForm roomSelected={false} />
        </div>
    )
}