import React from "react";
import ClientsDataForm from "./clientsDataForm";

const CustomersCreate = () => {
    
      return (
        <ClientsDataForm clientSelected={""} isUpdate={false} setClientSelected={""}/>
      );
}

export default CustomersCreate;