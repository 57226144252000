import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import LoaderSpinner from "./LoaderSpinner";
import LoadingButton from "./LoaderButton";
import { v1 as uuidv1 } from "uuid";
import { createRoomApi, updateRoomApi } from "../libs/postApi_Lib";

const mapStateToProps = (state, props) => ({
    clientData: state.clientData,
    roomsData: state.roomsData,
    roomSelected: props.roomSelected,
    setRoomSelected: props.setRoomSelected
});

const mapDispatchToProps = dispatch => ({
    setIncomingMsg(incomingMsgCenter){
        dispatch({
            type: "SHOW_MSG_CENTER",
            incomingMsgCenter
        })
    }
});

const formatWelcomeMessage = (msg) => {
    var msgt = msg.trim();
    msgt = encodeURIComponent(msgt);
    msgt = msgt.split('%20').join('+');
    return msgt;
}

const unformatWelcomeMessage = (msg) => {
    var msgt = decodeURIComponent(msg);
    msgt = msgt.split('+').join(' ');
    return msgt;
}

const transfromStringToBool = (String) => {
    switch (String) {
        case "Si":
            return true;

        case "No":
            return false;
    
        default:
            break;
    }
}

const transformBoolToString = (Bool) => {
    switch (Bool) {
        case true:
            return "Si";

        case false:
            return "No";
    
        default:
            break;
    }
}

const trimer = (text, clearSpacesBetween) => {
    var txt = text.trim();
    if (clearSpacesBetween){
        txt = txt.split(' ').join('');
    } else {
        txt = encodeURIComponent(txt);
    }
    return txt;
}

const RoomsDataForm = ({clientData, roomData, roomSelected, setRoomSelected, setIncomingMsg}) => {
    const [okToRender, setOkToRender] = useState (false);
    const [textButton, setTextButton] = useState("Guardar");
    var cOr = {
        clientId: "",
        id_tributaria: "",
        nombre: "",
        servidores:[]
    };
    var clientsArray = [];
    var localRoomData = {
        name: "",
        welcome: "<br>Welcome to <b>%%CONFNAME%%</b>!",
        maxParticipants: 0,
        record: "Si",
        autoStartRecording: "No",
        allowStartStopRecording: "Si",
        duration: 0,
        webcamsOnlyForModerator: "No",
        muteOnStart: "Si",
        allowModsToUnmuteUsers: "No",
        lockSettingsDisableCam: "No",
        lockSettingsDisableMic: "No",
        lockSettingsDisablePrivateChat: "No",
        lockSettingsDisableNote: "No",
        guestPolicy: "Si",
        Servidor: ""
    };
    if(roomSelected){
        for (let i = 0; i < clientData.length; i++) {
            const element = clientData[i];
            if (element.clientId === roomData.clienteId){
                clientsArray.push(element);
            }
        }
        cOr = clientsArray[0];
        localRoomData = {
            name: decodeURIComponent(roomData.roomName),
            welcome: unformatWelcomeMessage(roomData.welcome),
            maxParticipants: roomData.maxParticipants,
            record: transformBoolToString(roomData.recordRoom),
            autoStartRecording: transformBoolToString(roomData.autoStartRecording),
            allowStartStopRecording: transformBoolToString(roomData.allowStartStopRecording),
            duration: roomData.roomDuration,
            webcamsOnlyForModerator: transformBoolToString(roomData.webcamsOnlyForModerator),
            muteOnStart: transformBoolToString(roomData.muteOnStart),
            allowModsToUnmuteUsers: transformBoolToString(roomData.allowModsToUnmuteUsers),
            lockSettingsDisableCam: transformBoolToString(roomData.lockSettingsDisableCam),
            lockSettingsDisableMic: transformBoolToString(roomData.lockSettingsDisableMic),
            lockSettingsDisablePrivateChat: transformBoolToString(roomData.lockSettingsDisablePrivateChat),
            lockSettingsDisableNote: transformBoolToString(roomData.lockSettingsDisableNote),
            guestPolicy: (roomData.guestPolicy === "ALWAYS_ACCEPT" ? "No" : "Si"),
            Servidor: roomData.Servidor
        };
    } else {
        for (let i = 0; i < clientData.length; i++) {
            const element = clientData[i];
            let client = {
                clientId: element.clientId,
                id_tributaria: element.id_tributaria,
                nombre: element.nombre,
                servidores: element.servidores
            };
            clientsArray.push(client);
        }
    }
    const [roomName, setRoomName] = useState(localRoomData.name);
    const [customWelcomeMessage, setCustomWelcomeMessage] = useState(localRoomData.welcome);
    const [maxParticipantPermited, setMaxParticipantPermited] = useState(localRoomData.maxParticipants);
    const [meetingCanBeRecorder, setMeetingCanBeRecorder] = useState(localRoomData.record);
    const [autoRecordMeetingOnStart, setAutoRecordMeetingOnStart] = useState(localRoomData.autoStartRecording);
    const [allowStopStarRecording, setAllowStopStarRecording] = useState(localRoomData.allowStartStopRecording);
    const [meetingMaxDuration, setMeetingMaxDuration] = useState(localRoomData.duration);
    const [camOnlyForModerators, setCamOnlyForModerators] = useState(localRoomData.webcamsOnlyForModerator);
    const [participanJoinMuted, setParticipanJoinMuted] = useState(localRoomData.muteOnStart);
    const [moderatorCanEnableParticipantMic, setModeratorCanEnableParticipantMic] = useState(localRoomData.allowModsToUnmuteUsers);
    const [meetingWithOutCameras, setMeetingWithOutCameras] = useState(localRoomData.lockSettingsDisableCam);
    const [disableMicsOnJoin, setDisableMicsOnJoin] = useState(localRoomData.lockSettingsDisableMic);
    const [disablePrivateChat, setDisablePrivateChat] = useState(localRoomData.lockSettingsDisablePrivateChat);
    const [disabledNotes, setDisabledNotes] = useState(localRoomData.lockSettingsDisableNote);
    const [askModeratorToJoin, setAskModeratorToJoin] = useState(localRoomData.guestPolicy);
    const [serverRoom, setServerRoom] = useState(localRoomData.Servidor);
    
    const [isLoading, setIsLoading] = useState(false);

    const [clientOwnerRoom, setClienteOwnerRoom] = useState([cOr]);

    useEffect(()=>{
        onLoad();
    }, []);

    //FUNCIONES
    const handleClientChange = (event) => {
        var cor = [...clientOwnerRoom];
        if (event === ""){
            cor.splice(0,cor.length);
            const corClean = {
                clientId: "",
                id_tributaria: "",
                nombre: "",
                servidores:[]
            }
            cor.push(corClean);
            setClienteOwnerRoom(cor);
        } else {
            cor.splice(0,cor.length);
            cor.push(clientsArray[event]);
            setClienteOwnerRoom(cor);
        }
    }; 

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setTextButton("");
        let name = trimer(roomName, false);
        var meetingID = "";
        if (roomSelected){
            meetingID = roomData.meetingID;
        } else {
            meetingID = uuidv1();
        }
        let cId = clientOwnerRoom[0].clientId.trim();
        let allowSSR = transfromStringToBool(allowStopStarRecording);
        let attendeePW = uuidv1();
        let autoStartRecording = transfromStringToBool(autoRecordMeetingOnStart);
        let duration = meetingMaxDuration;
        let maxParticipants = maxParticipantPermited;
        let moderatorPW = uuidv1();
        let record = transfromStringToBool(meetingCanBeRecorder);
        let welcome = formatWelcomeMessage(customWelcomeMessage);
        let webcamsOnlyForModerator = transfromStringToBool(camOnlyForModerators);
        let muteOnStart = transfromStringToBool(participanJoinMuted);
        let allowModsToUnmuteUsers = transfromStringToBool(moderatorCanEnableParticipantMic);
        let lockSettingsDisableCam = transfromStringToBool(meetingWithOutCameras);
        let lockSettingsDisableMic = transfromStringToBool(disableMicsOnJoin);
        let lockSettingsDisablePrivateChat = transfromStringToBool(disablePrivateChat);
        let lockSettingsDisableNote = transfromStringToBool(disabledNotes);
        let guestPolicy = (askModeratorToJoin === "Si" ? "ASK_MODERATOR" : "ALWAYS_ACCEPT");

        let urlJoinServerDispatcher = "https://vcm.gvalor.co/join";
        let urlRecordServerDispatcher = "https://vcm.gvalor.co/recordings"
        let urlAccesoModerador = urlJoinServerDispatcher+"?mId="+meetingID+"&cId="+cId+"&pswd="+moderatorPW;
        let urlAccesoParticipant = urlJoinServerDispatcher+"?mId="+meetingID+"&cId="+cId+"&pswd="+attendeePW;
        let urlRecordings = urlRecordServerDispatcher+"?mId="+meetingID;

        let axios = require('axios');
        var tynyModURL = "";
        var tynyPartURL = "";
        if (!roomSelected){
            console.log("generando tynyUrls");
            await axios.get('https://tinyurl.com/api-create.php?url='+urlAccesoModerador)
            .then(function (response) {
                tynyModURL = response.data;                
            })
            .catch(function (error) {
                // handle error
                //Enviar a msgCenter notificacion del error
                console.log("Repuesta Error:");
                console.log(error);
            })
            .then(function () {
                // always executed
                console.log("Ok Moderador");
            });

        
            await axios.get('https://tinyurl.com/api-create.php?url='+urlAccesoParticipant)
            .then(function (response) {
                tynyPartURL = response.data;                
            })
            .catch(function (error) {
                // handle error
                //Enviar a msgCenter notificacion del error
                console.log("Repuesta Error:");
                console.log(error);
            })
            .then(function () {
                // always executed
                console.log("Ok participantes");
            });
        }
        var room = "";
        if (roomSelected){
            room ={
                allowStartStopRecording: allowSSR,
                roomName: name,
                clienteId: roomData.clienteId,
                roomId: roomData.roomId,
                autoStartRecording: autoStartRecording,
                roomDuration: duration,
                maxParticipants: maxParticipants,
                recordRoom: record,
                welcome: welcome,
                webcamsOnlyForModerator: webcamsOnlyForModerator,
                muteOnStart: muteOnStart,
                allowModsToUnmuteUsers: allowModsToUnmuteUsers,
                lockSettingsDisableCam: lockSettingsDisableCam,
                lockSettingsDisableMic: lockSettingsDisableMic,
                lockSettingsDisablePrivateChat: lockSettingsDisablePrivateChat,
                lockSettingsDisableNote: lockSettingsDisableNote,
                guestPolicy: guestPolicy,
                Servidor: serverRoom,
             }
        } else {
            room ={
                allowStartStopRecording: allowSSR,
                roomName: name,
                roomId: meetingID,
                attendeePW: attendeePW,
                autoStartRecording: autoStartRecording,
                roomDuration: duration,
                maxParticipants: maxParticipants,
                moderatorPW: moderatorPW,
                recordRoom: record,
                welcome: welcome,
                webcamsOnlyForModerator: webcamsOnlyForModerator,
                muteOnStart: muteOnStart,
                allowModsToUnmuteUsers: allowModsToUnmuteUsers,
                lockSettingsDisableCam: lockSettingsDisableCam,
                lockSettingsDisableMic: lockSettingsDisableMic,
                lockSettingsDisablePrivateChat: lockSettingsDisablePrivateChat,
                lockSettingsDisableNote: lockSettingsDisableNote,
                guestPolicy: guestPolicy,
                Servidor: serverRoom,
                clienteId: cId,
                urlAccesoModerador: urlAccesoModerador,
                urlAccesoParticipantes: urlAccesoParticipant,
                urlTablaGrabaciones: urlRecordings,
                tynyUrlAccesoModerador: tynyModURL,
                tynyUrlAccesoParticipantes: tynyPartURL
             }
        }
        console.log("Salvando datos de sala");
        if (roomSelected){
            updateRoomApi(room)
            .then((response)=>{
                const time = new Date();
                const hour = time.getHours();
                const minute = time.getMinutes();
                const incomingMsgCenter = {
                    title: "Rooms",
                    time: hour + ":" + minute,
                    text: response.msg,
                    show: true
                };
                setIncomingMsg(incomingMsgCenter);
            }).catch(error => {
                console.log(error);
            });
            setRoomSelected(false);
         } else {
            createRoomApi(room)
            .then((response)=>{
                const time = new Date();
                const hour = time.getHours();
                const minute = time.getMinutes();
                const incomingMsgCenter = {
                    title: "Rooms",
                    time: hour + ":" + minute,
                    text: response.msg,
                    show: true
                };
                setIncomingMsg(incomingMsgCenter);
                window.location.reload(false);
            }).catch(error => {
                console.log(error);
            });
        }
    }

    const validateRoomCreation = () => {
        return clientOwnerRoom[0].clientId.length > 0 &&
        serverRoom.length > 0 &&
        roomName.length > 0 &&
        customWelcomeMessage.length > 0;
    }

    const onLoad = () => {
        setOkToRender(true);
        
    }
    //RENDER
    if(okToRender){
        return (
            <Col md={8} className="mt-3">
                <form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="clientOwnerRoom">
                        <label className="form-label">Cliente</label>
                        <Form.Control 
                            type="text"
                            as="select"
                            onChange={e=>handleClientChange(e.target.value)}
                        >
                            {roomSelected === false ? <option></option> : ""}
                            {clientsArray.map((v,i)=> {
                                return (
                                    <option label={v.id_tributaria + " | " + v.nombre} value={i} key={i}/>
                                )
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Row} controlId="clientServers">
                        <label className="form-label">Servidor</label>
                        <Form.Control 
                            type="text"
                            as="select"
                            onChange={e=>setServerRoom(e.target.value)}
                        >
                            {roomSelected === true ? <option label={roomData.Servidor} value={roomData.Servidor}></option> : <option></option>}
                            {clientOwnerRoom[0].servidores.map((v,i)=>{
                                    return (
                                        <option label={v.servidor} value={v.servidor} key={i}/>
                                    )
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Row} controlId="roomName">
                        <label className="form-label">Nombre de la sala</label>
                        <Form.Control 
                            type="text"
                            value={roomName}
                            onChange={e=>setRoomName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group as={Row} controlId="roomName">
                        <label className="form-label">Mensaje de bienvenida</label>
                        <Form.Control 
                            type="text"
                            as="textarea"
                            rows="3"
                            value={customWelcomeMessage}
                            onChange={e=>setCustomWelcomeMessage(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group as={Row} controlId="parametersGroup1" className="align-items-end">
                        <Col>
                            <label className="form-label">Participantes (0 sin limite)</label>
                            <Form.Control 
                                type="number"
                                value={maxParticipantPermited}
                                onChange={e=>setMaxParticipantPermited(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <label className="form-label">Permitir grabaciones</label>
                            <Form.Control 
                                type="text"
                                as="select"
                                value={meetingCanBeRecorder}
                                onChange={e=>setMeetingCanBeRecorder(e.target.value)}
                            >
                                <option>Si</option>
                                <option>No</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <label className="form-label">Grabar al iniciar</label>
                            <Form.Control 
                                type="text"
                                as="select"
                                value={autoRecordMeetingOnStart}
                                onChange={e=>setAutoRecordMeetingOnStart(e.target.value)}
                            >
                                <option>Si</option>
                                <option>No</option>
                            </Form.Control>
                        </Col>
                    </Form.Group><hr></hr>
                    <Form.Group as={Row} controlId="parametersGroup2" className="align-items-end">
                        <Col>
                            <label className="form-label">Permitir iniciar y detener la grabación</label>
                            <Form.Control 
                                type="text"
                                as="select"
                                value={allowStopStarRecording}
                                onChange={e=>setAllowStopStarRecording(e.target.value)}
                            >
                                <option>Si</option>
                                <option>No</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <label className="form-label">Duración máxima de cada sesión en minutos (0 sin limite)</label>
                            <Form.Control 
                                type="number"
                                value={meetingMaxDuration}
                                onChange={e=>setMeetingMaxDuration(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <label className="form-label">Mostrar camaras solo a los moderadores</label>
                            <Form.Control 
                                type="text"
                                as="select"
                                value={camOnlyForModerators}
                                onChange={e=>setCamOnlyForModerators(e.target.value)}
                            >
                                <option>Si</option>
                                <option>No</option>
                            </Form.Control>
                        </Col>
                    </Form.Group><hr></hr>
                    <Form.Group as={Row} controlId="parametersGroup3" className="align-items-end">
                        <Col>
                            <label className="form-label">Ingresar a la sala con microfono silenciado</label>
                            <Form.Control 
                                type="text"
                                as="select"
                                value={participanJoinMuted}
                                onChange={e=>setParticipanJoinMuted(e.target.value)}
                            >
                                <option>Si</option>
                                <option>No</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <label className="form-label">Permitir a los moderadores encender el microfono de los participante</label>
                            <Form.Control 
                                type="text"
                                as="select"
                                value={moderatorCanEnableParticipantMic}
                                onChange={e=>setModeratorCanEnableParticipantMic(e.target.value)}
                            >
                                <option>Si</option>
                                <option>No</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <label className="form-label">Deshabilitar uso de las camaras</label>
                            <Form.Control 
                                type="text"
                                as="select"
                                value={meetingWithOutCameras}
                                onChange={e=>setMeetingWithOutCameras(e.target.value)}
                            >
                                <option>Si</option>
                                <option>No</option>
                            </Form.Control>
                        </Col>
                    </Form.Group><hr></hr>
                    <Form.Group as={Row} controlId="parametersGroup4" className="align-items-end">
                        <Col>
                            <label className="form-label">Deshabilitar microfono para todos los participantes</label>
                            <Form.Control 
                                type="text"
                                as="select"
                                value={disableMicsOnJoin}
                                onChange={e=>setDisableMicsOnJoin(e.target.value)}
                            >
                                <option>Si</option>
                                <option>No</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <label className="form-label">Deshabilitar chats privados</label>
                            <Form.Control 
                                type="text"
                                as="select"
                                value={disablePrivateChat}
                                onChange={e=>setDisablePrivateChat(e.target.value)}
                            >
                                <option>Si</option>
                                <option>No</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <label className="form-label">Deshabilitar notas</label>
                            <Form.Control 
                                type="text"
                                as="select"
                                value={disabledNotes}
                                onChange={e=>setDisabledNotes(e.target.value)}
                            >
                                <option>Si</option>
                                <option>No</option>
                            </Form.Control>
                        </Col>
                    </Form.Group><hr></hr>
                    <Form.Group as={Row} controlId="parametersGroup5" className="align-items-end">
                        <Col>
                            <label className="form-label">Solicitar autorización al moderador para unirse</label>
                            <Form.Control 
                                type="text"
                                as="select"
                                value={askModeratorToJoin}
                                onChange={e=>setAskModeratorToJoin(e.target.value)}
                            >
                                <option>Si</option>
                                <option>No</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <hr></hr>
                    <Row className="justify-content-md-end">
                        <Col sm={4}>
                            <LoadingButton 
                                block
                                type="submit"
                                isLoading={isLoading}
                                disabled={!validateRoomCreation()}
                            > {textButton}
                            </LoadingButton>
                        </Col>
                    </Row>
                </form>
            </Col>
        )
    } else {
        return <LoaderSpinner />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomsDataForm)