import React, {useState} from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./login.css";
import { Auth, API } from "aws-amplify";
import LoadingButton from "../components/LoaderButton";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux" ;

const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    stateUserData: state.userData
})

const mapDispatchToProps = dispatch => ({
    setLoginState(auth_request){
        dispatch({
            type: "USER_PERFORM_LOGIN",
            auth_request
        })
    },
    setUserData(newUserData){
        dispatch({
            type: "SAVE_USER_DATA",
            newUserData
        })
    },
    setIncomingMsg(incomingMsgCenter){
        dispatch({
            type: "SHOW_MSG_CENTER",
            incomingMsgCenter
        })
    }
})

async function loadUserData(userEmail, setUserData){
    API.get("vcm", "/getUserData", {
        'queryStringParameters': {
            'email': userEmail
        }
    }).then(function (response){
        if(response.result === "fail"){
            let user = {
                email: userEmail,
                rol:"guest",
                nombre:"",
                apellido:"",
                zona:"",
                cliente:""
            };
            API.post("vcm", "/createUsers", {
                body: user
            }).then((value)=>{
                loadUserData(userEmail, setUserData);
            }).catch(error=>{
                console.log(error);
            })
        } else {
            const newUserData = {
                userData: [{
                    userId: response.userId,
                    nombre: response.nombre,
                    apellido: response.apellido,
                    email: response.email,
                    rol: response.rol,
                    cliente: response.cliente,
                    zona: response.zona
                }],
            };
            setUserData(newUserData);
        }

    }).catch(function(err) {
        console.log(err);
        //Enviar notificacion a MsgCenter => no se pudo encontrar la información del usuario
    });
}

function Login({isAuthenticated, stateUserData, setLoginState, setUserData, setIncomingMsg}){
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [textButton, setTextButton] = useState("Ingresar");
    const history = useHistory();

    function validarLogin(){
        return email.length > 0 && pass.length > 0;
    }

    async function handleSubmit(event) {
        
        event.preventDefault();
        setTextButton("");
        setIsLoading(true);
        try{
            await Auth.signIn(email, pass);
            console.log("Ok Login");
            const auth_request = {
                isAuthenticated: [{
                    authenticated: true
                }]
            }
            setLoginState(auth_request);
            await loadUserData(email, setUserData);
            setIsLoading(false);
            history.push("/console");
        } catch(error){
            //console.log(error);
            const time = new Date();
            const hour = time.getHours();
            const minute = time.getMinutes();
            const incomingMsgCenter = {
                title: "Inicio de sesión",
                time: hour + ":" + minute,
                text: "Usuario o clave incorrectos",
                show: true
            };
            setIncomingMsg(incomingMsgCenter);
            setTextButton("Intenta de nuevo");
            setIsLoading(false);
        }
    }
    
    return (
        <Row className="justify-content-md-center login-container">
            <Col md={4}>
                <form onSubmit={handleSubmit}>
                    <Form.Group controlId="email">
                        <label className="form-label">Email</label>
                        <Form.Control
                            autoFocus
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="pass">
                        <label className="form-label">Clave</label>
                        <Form.Control
                            type="password"
                            value={pass}
                            onChange={e => setPass(e.target.value)}
                        />
                    </Form.Group>
                    <LoadingButton 
                        block
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validarLogin()}
                    > {textButton}
                    </LoadingButton>
                </form>
            </Col>
        </Row>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);