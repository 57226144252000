import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { Row, Col, Table, Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import LoaderSpinner from "./LoaderSpinner";
import UserDataForm from "./usersDataForm";

const mapStateToProps = state => ({
    usersData: state.usersData
});

const mapDispatchToProps = dispatch => ({
    setUsersData(updateUsersData){
        dispatch({
            type: "SET_USERS_DATA",
            updateUsersData
        })
    },
    setIncomingMsg(incomingMsgCenter){
        dispatch({
            type: "SHOW_MSG_CENTER",
            incomingMsgCenter
        })
    },
});

const retrieveUsers = (setUsersData, setIncomingMsg) => {
    console.log("Actualizando usuarios");
    API.get("vcm", "/getUsers")
    .catch(error => {
        console.log(error);
        const time = new Date();
        const hour = time.getHours();
        const minute = time.getMinutes();
        const incomingMsgCenter={
            title: "Usuarios",
            time: hour + ":" + minute,
            text: "Error obteniendo usuarios",
            show: true
        }
        setIncomingMsg(incomingMsgCenter);
    }).then((value)=>{
        if (value.msg === "Permiso denegado"){
            const time = new Date();
            const hour = time.getHours();
            const minute = time.getMinutes();
            const incomingMsgCenter={
                title: "Usuarios",
                time: hour + ":" + minute,
                text: value.msg,
                show: true
            }
            setIncomingMsg(incomingMsgCenter);
        } else {
            setUsersData(value);
        }
    });
};

const renderUsersList = (usersData, setUserSelected, setUsersData) => {
    var usersToList = [];
    for (let i = 0; i < usersData.length; i++) {
        const element = usersData[i];
        const ud = {
            "userId": element.userId,
            "email": element.email,
            "nombre": element.nombre,
            "apellido": element.apellido
        }
        usersToList.push(ud);
    }
    return (
        <div className="client-container">
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>email</th>
                        <th>nombre</th>
                    </tr>
                </thead>
                <tbody>
                    {usersToList.map((v,i) => {
                        return (
                            <tr key={i}>
                                <td name={"email"+i}>{v.email}</td>
                                <td name={"nombre"+i}>{v.nombre + " " + v.apellido}</td>
                                <td>
                                    <Button variant="success" title="Editar" onClick={() => setUserSelected(v.userId)}><i className="far fa-edit"></i></Button>
                                    <DropdownButton 
                                        title={<i className="far fa-trash-alt"></i>}
                                        variant="danger"
                                        as={ButtonGroup}
                                        >
                                            <Dropdown.Header>¿Seguro?</Dropdown.Header>
                                            <Dropdown.Item onClick={()=> deleteUser(v.userId, setUsersData)} as="button">Si, elimina este usuario</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

const deleteUser = () => {

}

const UserEdit = ({usersData, setUsersData, setIncomingMsg}) => {
    useEffect(()=>{
        retrieveUsers(setUsersData, setIncomingMsg);
    }, [setUsersData, setIncomingMsg]);
    const [userSelected, setUserSelected] = useState(false);
    if(usersData.length > 0){
        if (userSelected === false){
            return (
                <div>
                    <Row className="action-buttons-container mt-3">
                        <Col className="text-right">
                            <Button onClick={()=>retrieveUsers(setUsersData, setIncomingMsg)} title="Recargar"><i className="fas fa-sync-alt"></i></Button>
                        </Col>
                    </Row>
                    <hr></hr>
                    {renderUsersList(usersData, setUserSelected, setUsersData)}
                </div>
            );
        } else {
            return (
                <div>
                    <Row className="action-buttons-container mt-3">
                        <Col className="text-right">
                            <Button onClick={()=>setUserSelected(false)} title="Volver"><i className="fas fa-chevron-left"></i></Button>
                        </Col>
                    </Row>
                    <hr></hr>
                    <UserDataForm userSelected={userSelected} setUserSelected={setUserSelected}/>
                </div>
            );
        }
    } else {
        return (
            <div>
                <LoaderSpinner />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);